import React, { ReactNode } from 'react';

interface IShortToast {
  icon?: ReactNode;
  text: string;
}

const ShortToast: React.FC<IShortToast> = ({ icon, text }) => {
  return (
    <div className="flex items-center justify-center gap-2 px-4 py-2 relative bg-grey-50 rounded-lg shadow-main">
      {icon && <div>{icon}</div>}
      <div className="text-blue-primary text-regular font-bold">{text}</div>
    </div>
  );
};

export default ShortToast;
