import React from 'react';

import Delimiter from '../../Delimiter';
import { useAppSelector } from '../../../../hooks/hooks';
import { formatClassDate } from '../../../../helpers';
import { TCartItem } from '../../../../types';

interface ICheckoutList {
  subscriptionIncluded?: boolean;
}

const CheckoutList: React.FC<ICheckoutList> = ({
  subscriptionIncluded = false,
}) => {
  const { cart, user } = useAppSelector((state) => state.userReducer);

  const renderItem = (item: TCartItem) => {
    return (
      <p key={item.id}>
        <span className="font-bold">{item.course.name}, </span>
        {formatClassDate(item.startTime, item.endTime, true)}
      </p>
    );
  };

  const renderItemGroup = (items: TCartItem[], header: string) => {
    if (!items.length) {
      return null;
    }
    return (
      <div className="flex flex-col gap-2">
        <p className="text-regular font-bold">{header}</p>
        {items.map(renderItem)}
      </div>
    );
  };

  const publicItems = subscriptionIncluded
    ? cart.public.filter((item) => item.included)
    : cart.public;

  return (
    <div className="flex flex-col text-regular text-core-dark font-medium">
      {renderItemGroup(cart.private, 'Private classes')}
      {cart.private.length > 0 && publicItems.length > 0 && (
        <Delimiter className="bg-grey-200 my-4"></Delimiter>
      )}
      {renderItemGroup(publicItems, 'Public classes')}
      {subscriptionIncluded && publicItems.length > 0 && user?.program && (
        <div className="mt-2">
          You are eligible for {user?.program.type} subscription type with
          Learnit which means the total price for public classes below is
          <span className="font-bold"> USD 0.00</span>
        </div>
      )}
    </div>
  );
};

export default CheckoutList;
