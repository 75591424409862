import React, { useState } from 'react';

import { icons } from '../../../../helpers/icons';
import { ButtonRegular } from '../../buttons/ButtonRegular';
import { Link } from 'react-router-dom';

interface IEvaluationButton {
  evaluationLink: string;
}

const EvaluationButton: React.FC<IEvaluationButton> = ({ evaluationLink }) => {
  const [evaluated, setEvaluated] = useState(false);
  return (
    <>
      {evaluated ? (
        <div className="flex items-center gap-2">
          <div>
            <img src={icons.tickCircle} alt="" />
          </div>
          <div className="text-regular font-medium text-green-900">
            Submitted
          </div>
        </div>
      ) : (
        <Link to={evaluationLink} target="_blank">
          <ButtonRegular
            onClick={() => setEvaluated(true)}
            className="button-primary"
            text="Submit evaluation"
          />
        </Link>
      )}
    </>
  );
};

export default EvaluationButton;
