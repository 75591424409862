import React from 'react';

import LoadingImage from '../../../../../LoadingImage';
import { icons } from '../../../../../../../helpers/icons';
import {
  formatClassDate,
  formatToDuration,
} from '../../../../../../../helpers';
import { TCartItem } from '../../../../../../../types';

interface ICheckoutItemPreview {
  item: TCartItem;
}

const CheckoutItemPreview: React.FC<ICheckoutItemPreview> = ({ item }) => {
  return (
    <div className="md:min-w-[14rem] flex gap-2">
      <LoadingImage
        className="w-24 h-full max-h-24 object-cover rounded-lg shrink"
        src={item.course.imageUrl ?? icons.coursePlaceholder}
      />
      <div>
        <div className="text-lg text-black font-bold">{item.course.name}</div>
        <div>{formatClassDate(item.startTime, item.endTime)}</div>
        <div>{formatToDuration(item.startTime, item.endTime)}</div>
      </div>
    </div>
  );
};

export default CheckoutItemPreview;
