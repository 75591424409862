import React, { useEffect, useState } from 'react';

import CourseFilters from '../../../components/common/library/filter/CourseFilters';
import CourseFilterButton from '../../../components/common/library/filter/CourseFilterButton';
import CourseGroup from '../../../components/common/library/CourseGroup';
import CourseSearch from '../../../components/common/library/CourseSearch';
import NavHeader from '../../../components/common/NavHeader';
import { getPublicCourses } from '../../../requests';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { setCourses } from '../../../store/reducers/librarySlice';
import CourseGroupSkeleton from '../../../components/common/library/CourseGroupSkeleton';
import SubNavigation from '../../../components/common/SubNavigation';
import { getUserNavigation } from '../../../helpers';

const PublicCourses = () => {
  const dispatch = useAppDispatch();
  const { filteredCourses } = useAppSelector((state) => state.libraryReducer);
  const { user } = useAppSelector((state) => state.userReducer);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPublicCourses()
      .then((courses) => {
        dispatch(setCourses(courses));
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  const navigationItems = getUserNavigation(user).library.items;

  return (
    <>
      <NavHeader label="Learnit courses" />
      {user && navigationItems.length > 1 && (
        <SubNavigation items={navigationItems} />
      )}
      <div className="flex flex-col bg-grey-50 min-h-screen">
        <CourseSearch />
        <div className="xl:hidden max-sm:px-2 px-5 py-4 flex justify-end">
          <CourseFilterButton />
        </div>
        <div className="w-full guest:guest-wrapper guest:mx-auto h-full main-container max-sm:px-2 xl:guest:px-0 pb-4 xl:py-10 bg-grey-50 flex-row gap-5">
          <CourseFilters />
          <div className="w-full max-w-[980px] flex flex-col gap-10">
            {loading ? (
              <CourseGroupSkeleton />
            ) : (
              filteredCourses.map((courseGroup) => (
                <CourseGroup
                  key={courseGroup.groupName}
                  courseGroup={courseGroup}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicCourses;
