import React from 'react';

import ConfirmationPopup from '../../common/ConfirmationPopup';
import { formatToSimpleDateTime, showToast } from '../../../helpers';
import { TClass } from '../../../types';
import { requestClassCancel } from '../../../requests';
import { DEFAULT_ERROR_MESSAGE } from '../../../helpers/constants';

interface IClassCancellationPopup {
  open: boolean;
  setOpen: (open: boolean) => void;
  classInfo: TClass;
}

const ClassCancellationPopup: React.FC<IClassCancellationPopup> = ({
  open,
  setOpen,
  classInfo,
}) => {
  const handleConfirm = async () => {
    try {
      await requestClassCancel(classInfo.id);
      setOpen(false);
      showToast('Class cancellation request sent successfully', 'success');
    } catch (error) {
      showToast(DEFAULT_ERROR_MESSAGE, 'error');
    }
  };

  return (
    <ConfirmationPopup
      title="Request Cancel Class"
      open={open}
      setOpen={setOpen}
      cancelText="Go back"
      confirmText="Request cancellation"
      onConfirm={handleConfirm}
    >
      <div className="flex flex-col gap-2 text-left">
        <div className="text-blue-primary uppercase font-medium">
          {formatToSimpleDateTime(classInfo.startTime)}
        </div>
        <div className="text-heading-dark whitespace-normal">
          {classInfo.name}
        </div>
        <div className="text-regular font-medium text-grey-700">
          You are about to cancel your class. Please continue if you are
          certain.
        </div>
      </div>
    </ConfirmationPopup>
  );
};

export default ClassCancellationPopup;
