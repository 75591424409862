import React, { useEffect, useRef } from 'react';

import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import LoadingOverlay from '../../../components/common/LoadingOverlay';
import { showPostInviteMessage, showToast } from '../../../helpers';
import {
  INVITE_ERROR_MESSAGE,
  NAVIGATE_PATH,
} from '../../../helpers/constants';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { acceptInvite } from '../../../requests';
import { fetchUser } from '../../../store/reducers/actionCreators';

const Invite: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const sfLearnitCourseId = searchParams.get('sfLearnitCourseId');
  const sfProgramId = searchParams.get('sfProgramId');

  const { user, isLoading } = useAppSelector((state) => state.userReducer);
  const hasRunOnce = useRef(false);

  useEffect(() => {
    if (
      hasRunOnce.current ||
      (!user && isLoading) ||
      (!sfLearnitCourseId && !sfProgramId)
    ) {
      return;
    }

    hasRunOnce.current = true;

    acceptInvite({
      inviteType: sfLearnitCourseId ? 'privateClass' : 'program',
      sfLearnitCourseId,
      sfProgramId,
    })
      .then(({ resolvedInvite }) => {
        if (resolvedInvite) {
          // refetch user to show the enrolled program
          dispatch(fetchUser());
          navigate(NAVIGATE_PATH.upcomingClasses);
          showPostInviteMessage(resolvedInvite);
        } else {
          navigate(NAVIGATE_PATH.signUp);
        }
      })
      .catch((error) => {
        console.error(error);
        navigate(NAVIGATE_PATH.signUp);
        showToast(INVITE_ERROR_MESSAGE, 'error', { duration: 12000 });
      });
  }, []);

  if (!sfLearnitCourseId && !sfProgramId) {
    showToast(INVITE_ERROR_MESSAGE, 'error');
    return <Navigate to={NAVIGATE_PATH.signUp} />;
  }

  return (
    <LoadingOverlay
      text={`Please wait while we register you into the ${
        sfLearnitCourseId ? 'class' : 'program'
      }. This may take a few seconds.`}
    />
  );
};

export default Invite;
