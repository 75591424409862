import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  confirmPasswordValue,
  getConfirmPasswordValidate,
} from '../../../helpers/validateLoginData';
import { ButtonRegular } from '../../common/buttons/ButtonRegular';
import { FormInput } from '../../common/forms/FormsInput';
import { icons } from '../../../helpers/icons';
import AgreementDescription from '../../common/AuthLayout/AgreementDescription';
import { NAVIGATE_PATH } from '../../../helpers/constants';
import { setPassword } from '../../../requests';
import { TConfirmPasswordValue } from '../../../types';
import { showPostInviteMessage } from '../../../helpers';

export const ConfirmPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const routeParams = useParams();
  const [isLinkInvalid, setIsLinkInvalid] = useState(false);

  const handleSubmit = async (values: TConfirmPasswordValue) => {
    try {
      const response = await setPassword(values.password, routeParams.token);
      localStorage.setItem('token', response.accessToken);
      navigate(NAVIGATE_PATH.upcomingClasses);
      if (response.resolvedInvite) {
        showPostInviteMessage(response.resolvedInvite);
      }
    } catch (e: any) {
      if (e.response?.status == 400) {
        setIsLinkInvalid(true);
      } else {
        console.error(e);
      }
    }
  };

  return (
    <div className="form-container">
      <div className="flex items-end justify-end">
        <img
          className="form-illustration-right"
          src={icons.pencilIllustration}
          alt=""
        />
      </div>
      <Formik
        validate={getConfirmPasswordValidate}
        initialValues={confirmPasswordValue}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({
          handleSubmit,
          handleBlur,
          handleChange,
          values,
          touched,
          errors,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="lg:max-w-[440px] m-auto 2xl:m-0 grid justify-items-center">
              <div className="grid w-full mb-6 text-3xl font-bold leading-none tracking-tight justify-items-center md:text-4xl text-core-dark">
                Create new password
              </div>
              {isLinkInvalid && (
                <div className="message-container">
                  <div className="flex items-center justify-center">
                    <div className="hidden mr-2 sm:block shrink-0">
                      <img src={icons.clock} alt="" />
                    </div>
                    <div className="title-calistoga">
                      It looks like the password link timed out or is invalid
                    </div>
                  </div>
                  <div className="text-regular">
                    <div>Please request a new password link again.</div>
                    <Link
                      className="action-link"
                      to={NAVIGATE_PATH.resetPassword}
                    >
                      Request a new password link
                    </Link>
                  </div>
                </div>
              )}
              <FormInput
                handleChange={handleChange}
                className="w-full mb-6"
                name={'password'}
                type={'password'}
                handleBlur={handleBlur}
                value={values.password}
                placeholder="New password"
                touched={touched['password']}
                error={errors['password']}
              />
              <FormInput
                handleChange={handleChange}
                className="w-full mb-6"
                name={'confirmPassword'}
                type={'password'}
                handleBlur={handleBlur}
                value={values.confirmPassword}
                placeholder="Enter confirm password"
                touched={touched['password']}
                error={errors['confirmPassword']}
              />
              <AgreementDescription action="Create password" />
              <div className="grid w-full mt-4 justify-items-center">
                <ButtonRegular
                  className="button-primary"
                  type={'submit'}
                  text="Create password"
                  disabled={isSubmitting || isLinkInvalid}
                />
              </div>
              <div className="grid w-full mt-4 justify-items-center">
                <span className="font-medium">
                  Already have an account?
                  <Link to={NAVIGATE_PATH.login}>
                    <span className="text-blue-one"> Log in</span>
                  </Link>
                </span>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
