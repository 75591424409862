import React from 'react';

import Delimiter from '../../../Delimiter';
import { TCourse } from '../../../../../types';

interface ICourseOutline {
  course: TCourse;
}

const CourseOutline: React.FC<ICourseOutline> = ({ course }) => {
  return (
    <div className="course-section">
      <div className="guest-wrapper guest:mx-auto flex flex-col">
        <div className="calistoga text-4xl text-core-dark">Course Outline</div>
        <Delimiter className="mt-4 mb-10 bg-grey-200" />
        <div
          className="inner-html"
          dangerouslySetInnerHTML={{ __html: course.outline }}
        />
      </div>
    </div>
  );
};

export default CourseOutline;
