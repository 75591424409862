import React from 'react';

import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';

import {
  getResetPasswordValidate,
  resetPasswordValue,
} from '../../../helpers/validateLoginData';
import { ButtonRegular } from '../../common/buttons/ButtonRegular';
import { FormInput } from '../../common/forms/FormsInput';
import { icons } from '../../../helpers/icons';
import AgreementDescription from '../../common/AuthLayout/AgreementDescription';
import { NAVIGATE_PATH } from '../../../helpers/constants';

interface ILoginForm {
  handleClick: (email: string) => Promise<void>;
  create: boolean;
}

export const ResetPassForm: React.FC<ILoginForm> = ({
  handleClick,
  create,
}) => {
  const action = create ? 'confirmation' : 'reset';
  const onSubmit = async (values: { email: string }) => {
    await handleClick(values.email);
  };

  return (
    <div className="form-container">
      <div className="flex justify-end items-end">
        <img
          className="form-illustration-right"
          src={icons.pencilIllustration}
          alt=""
        />
      </div>
      <Formik
        validate={getResetPasswordValidate}
        initialValues={resetPasswordValue}
        onSubmit={onSubmit}
        validateOnBlur={false}
      >
        {({
          handleSubmit,
          handleBlur,
          handleChange,
          values,
          touched,
          errors,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="lg:max-w-[440px] m-auto 2xl:m-0 grid justify-items-center">
              <div className="form-title">
                {create ? 'Create password' : 'Forgot password'}
              </div>
              <FormInput
                handleChange={handleChange}
                className="mb-6 w-full"
                name={'email'}
                type={'email'}
                handleBlur={handleBlur}
                value={values.email}
                placeholder="Email"
                touched={touched['email']}
                error={errors['email']}
              />
              <AgreementDescription action={`Send ${action} link`} />
              <div className="grid justify-items-center w-full mt-4">
                <ButtonRegular
                  className="button-primary"
                  type={'submit'}
                  text={`Send ${action} link`}
                  disabled={isSubmitting}
                />
              </div>
            </div>
            <div className="grid justify-items-center w-full mt-4">
              <span className="font-medium">
                Remembered your password?
                <Link to={NAVIGATE_PATH.login}>
                  <span className="text-blue-one"> Log in</span>
                </Link>
              </span>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
