import React from 'react';

import { Link } from 'react-router-dom';

import LoadingImage from '../../../../LoadingImage';
import { TCoursePreview } from '../../../../../../types';
import { NAVIGATE_PATH } from '../../../../../../helpers/constants';
import { icons } from '../../../../../../helpers/icons';

interface ICourseSearchSuggestion {
  course: TCoursePreview;
}

const CourseSearchSuggestion: React.FC<ICourseSearchSuggestion> = ({
  course,
}) => {
  return (
    <Link
      className="p-1 flex gap-2 items-center rounded hover:bg-grey-100"
      to={`${NAVIGATE_PATH.publicCourses}/${course.slug}`}
    >
      <div>
        <LoadingImage
          className="w-6 h-6 object-cover rounded"
          src={course.imageUrl ?? icons.coursePlaceholder}
          alt=""
        />
      </div>
      <div className="flex grow justify-between text-regular font-medium cursor-pointer">
        <div className="text-core-dark">{course.name}</div>
        <div className="text-blue-primary">{course.priceRange}</div>
      </div>
    </Link>
  );
};

export default CourseSearchSuggestion;
