import React from 'react';

import LoadingImage from '../../../LoadingImage';
import Tag from '../../../Tag';
import SubscriptionTag from '../../../checkout/SubscriptionTag';
import { icons } from '../../../../../helpers/icons';
import { TCourse } from '../../../../../types';
import { useAppSelector } from '../../../../../hooks/hooks';
import { isClassSubscriptionIncluded } from '../../../../../helpers';

interface ICourseDetails {
  course: TCourse;
}

const CourseDetails: React.FC<ICourseDetails> = ({ course }) => {
  const { user } = useAppSelector((state) => state.userReducer);

  const included = isClassSubscriptionIncluded(
    course.nearestStartTime,
    user?.program,
  );

  return (
    <div className="flex flex-col gap-4">
      <div>
        <LoadingImage
          className="rounded-lg w-[6.25rem] h-[6.25rem] object-cover"
          src={course.imageUrl ?? icons.coursePlaceholder}
        />
      </div>
      <div className="calistoga text-4xl text-grey">{course.name}</div>
      <div className="flex gap-4 items-center">
        {course.priceRange && (
          <div className="text-2xl text-blue-primary font-bold">
            <span className={included ? 'line-through' : ''}>
              {`From ${course.priceRange}`}
            </span>
          </div>
        )}
        {included && <SubscriptionTag />}
      </div>
      <div
        className="inner-html"
        dangerouslySetInnerHTML={{ __html: course.prerequisites }}
      />
      {course.skills.length > 0 && (
        <div className="flex flex-wrap gap-4 text-grey-700">
          {course.skills.map((skill) => (
            <Tag key={skill.id} text={skill.name} color={'blue'} />
          ))}
        </div>
      )}
      {course.whoShouldAttend && (
        <div className="p-2 flex flex-col gap-2 bg-yellow-100">
          <div className="flex gap-2 items-center">
            <div>
              <img src={icons.lightBubble} alt="" />
            </div>
            <div className="text-regular text-grey font-bold">
              Who should attend?
            </div>
          </div>
          <div
            className="text-regular text-core-dark font-medium"
            dangerouslySetInnerHTML={{ __html: course.whoShouldAttend }}
          />
        </div>
      )}
    </div>
  );
};

export default CourseDetails;
