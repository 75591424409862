import React from 'react';

import { useNavigate } from 'react-router-dom';

import { icons } from '../../../../helpers/icons';

interface IBackButton {
  defaultRoute: string;
  usePrevious?: boolean;
}

const BackButton: React.FC<IBackButton> = ({ defaultRoute, usePrevious }) => {
  const navigate = useNavigate();

  const goBackOrNavigateDefault = () => {
    if (!usePrevious) {
      navigate(defaultRoute, { replace: true });
    } else {
      navigate(-1);
    }
  };

  return (
    <div
      onClick={goBackOrNavigateDefault}
      className="flex gap-2 items-center cursor-pointer"
    >
      <div className="shrink-0">
        <img src={icons.arrowLeft} alt="" />
      </div>
      <div className="max-sm:hidden text-heading-dark">Back</div>
    </div>
  );
};

export default BackButton;
