import React, { useState } from 'react';

import LogoIcon from '../../../assets/images/Learnit_logo.svg';
import { AuthLayout } from '../../../components/common/AuthLayout';
import { LogoButton } from '../../../components/common/buttons/LogoButton';
import { ResetPassForm } from '../../../components/forms/ResetPasswordForm';
import EmailSent from '../../../components/common/AuthLayout/EmailSent';
import InvalidEmail from '../../../components/common/AuthLayout/InvalidEmail';
import { login } from '../../../requests';

interface IResetPassword {
  create?: boolean;
}

export const ResetPassword: React.FC<IResetPassword> = ({ create = false }) => {
  const [emailSent, setEmailSent] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);

  const handleClick = async (email: string) => {
    try {
      const response = await login(email);
      if (response.email) {
        setEmailSent(true);
      }
    } catch (e: any) {
      if (e.response?.status == 401) {
        setIsEmailInvalid(true);
      }
      console.error(e);
    }
  };

  const renderResetPassword = () => {
    if (isEmailInvalid) {
      return <InvalidEmail />;
    }
    if (emailSent) {
      return (
        <EmailSent title={`${create ? 'Confirmation' : 'Reset'} link sent`} />
      );
    }
    return <ResetPassForm handleClick={handleClick} create={create} />;
  };

  return (
    <AuthLayout button={<LogoButton logo={LogoIcon} />}>
      {renderResetPassword()}
    </AuthLayout>
  );
};
