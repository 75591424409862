import React from 'react';

import { useNavigate } from 'react-router-dom';

import { ButtonRegular } from '../../../buttons/ButtonRegular';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import { NAVIGATE_PATH } from '../../../../../helpers/constants';
import { setIsCartOpen } from '../../../../../store/reducers/userSlice';

const CartTotal: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, cart, cartTotalToPay } = useAppSelector(
    (state) => state.userReducer,
  );

  if (cart.public.length + cart.private.length === 0) {
    return null;
  }

  const handleGoToCheckout = () => {
    dispatch(setIsCartOpen(false));
    if (user) {
      navigate(NAVIGATE_PATH.checkoutSummary);
    } else {
      navigate(NAVIGATE_PATH.checkoutLogin);
    }
  };

  return (
    <div className="px-2 flex flex-col gap-4">
      <div className="flex justify-between items-center text-2xl font-bold">
        <div>Cart total</div>
        <div className="text-blue-primary">${cartTotalToPay}</div>
      </div>
      <div className="flex flex-col gap-2 justify-center items-center">
        <ButtonRegular
          className="button-primary"
          text="Go to checkout"
          onClick={handleGoToCheckout}
        />
        {!user && (
          <div className="text-center">
            Select number of seats/delegates in the next screen.
          </div>
        )}
      </div>
    </div>
  );
};

export default CartTotal;
