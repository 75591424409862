import React from 'react';

import Company from './Company';
import NavigationTabs from './NavigationTabs';

const Navbar: React.FC = () => {
  return (
    <div
      id="navbar"
      className="fixed w-full lg:sticky z-50 lg:z-auto bottom-0 lg:top-0 lg:w-72 lg:h-screen flex flex-col shrink-0 justify-between items-center lg:items-start"
    >
      <Company />
      <NavigationTabs />
    </div>
  );
};

export default Navbar;
