import React from 'react';

import { Navigate } from 'react-router-dom';

import BackButton from '../../../components/common/buttons/BackButton';
import CheckoutAgreementForm from '../../../components/forms/CheckoutAgreementForm';
import CheckoutList from '../../../components/common/checkout/CheckoutList';
import OrderSummaryCard from '../../../components/common/checkout/OrderSummaryCard';
import Delimiter from '../../../components/common/Delimiter';
import NavHeader from '../../../components/common/NavHeader';
import { NAVIGATE_PATH } from '../../../helpers/constants';
import { useAppSelector } from '../../../hooks/hooks';

const FirstCheckoutStep: React.FC = () => {
  const { cart, user } = useAppSelector((state) => state.userReducer);

  if (!cart.public.length && !cart.private.length) {
    return <Navigate to={NAVIGATE_PATH.checkoutSummary} replace />;
  }

  if (!cart.public.some(({ included }) => included) && !cart.private.length) {
    return <Navigate to={NAVIGATE_PATH.secondCheckoutStep} replace />;
  }

  return (
    <>
      <NavHeader
        label={<BackButton defaultRoute={NAVIGATE_PATH.checkoutSummary} />}
      />
      <div className="main-container w-full md:pt-4 max-md:px-2 gap-2 bg-white">
        <div className="flex flex-col xl:flex-row gap-6 justify-between">
          <div className="flex flex-col flex-grow gap-4">
            <div className="checkout-header">
              Checking out with {user?.program?.type}
            </div>
            {user?.program && (
              <div className="text-regular text-grey-700 font-medium">
                In this step we will book all the classes that are included in
                your {user.program.type} subscription type.
              </div>
            )}
            <Delimiter className="bg-grey-200" />
            <div className="p-6 flex flex-col gap-4 text-regular text-core-dark font-medium bg-grey-50 rounded-2xl">
              <CheckoutList subscriptionIncluded />
              <CheckoutAgreementForm />
            </div>
          </div>
          <div className="w-full xl:max-w-[22rem] 3xl:min-w-[35%]">
            <OrderSummaryCard buttonHidden />
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstCheckoutStep;
