import React from 'react';

import Modal from '../../../Modal';
import CourseFilterList from '../CourseFilterList';
import { TNamedItem } from '../../../../../types';
import { filterCourses } from '../../../../../store/reducers/librarySlice';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';

interface ICourseFilterModal {
  onClose: () => void;
}

const CourseFilterModal: React.FC<ICourseFilterModal> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const { activeFilters, secondaryFilters } = useAppSelector(
    (state) => state.libraryReducer,
  );

  const handleFilterSelect = (secondaryFilter: TNamedItem | null) => {
    dispatch(filterCourses({ secondaryFilter }));
    onClose();
  };

  return (
    <Modal title="Filter by" onClose={() => onClose()}>
      <CourseFilterList
        filters={secondaryFilters}
        active={activeFilters.secondaryFilter}
        setActive={handleFilterSelect}
      />
    </Modal>
  );
};

export default CourseFilterModal;
