import React, { useState } from 'react';

import { icons } from '../../../helpers/icons';
import { TOption } from '../../../types';

interface ISelect {
  options: TOption[];
  active: string;
  setActive: (value: string) => void;
}

const Select: React.FC<ISelect> = ({ options, active, setActive }) => {
  const [open, setOpen] = useState(false);
  const handleSelect = (value: string) => {
    setActive(value);
    setOpen(false);
  };

  return (
    <div className="w-[9rem] flex flex-col gap-1 bg-blue-100 text-core-dark rounded font-medium text-regular whitespace-nowrap">
      <div
        onClick={() => setOpen(!open)}
        className="py-1 px-2 flex justify-between items-center cursor-pointer"
      >
        <div>{options.find((option) => option.value == active)?.text}</div>
        <div>
          <img src={icons.arrowDown} alt="" />
        </div>
      </div>
      <div
        className={`${
          !open ? 'hidden' : ''
        } w-[9rem] py-1 absolute mt-8 flex flex-col gap-1 rounded-b bg-white-one`}
      >
        {options.map((option, index) => {
          return (
            <div
              key={index}
              onClick={() => handleSelect(option.value)}
              className="px-2 hover:bg-gray-100 cursor-pointer"
            >
              {option.text}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Select;
