import React from 'react';
import ContentLoader from 'react-content-loader';

import Delimiter from '../../Delimiter';

const ClassRowSkeleton = () => {
  return (
    <>
      <div className="class-row">
        <div className="mb-4 xl:m-auto flex flex-col class-first-column items-start gap-2">
          <ContentLoader width="100%">
            <rect x="0" y="0" rx="5" ry="5" width="200" height="20" />
            <rect x="0" y="30" rx="5" ry="5" width="200" height="20" />
            <rect x="0" y="60" rx="5" ry="5" width="100%" height="15" />
            <rect x="0" y="85" rx="5" ry="5" width="100%" height="15" />
            <rect x="0" y="110" rx="5" ry="5" width="100%" height="15" />
            <rect x="0" y="135" rx="5" ry="5" width="100%" height="15" />
          </ContentLoader>
        </div>
        <div className="class-column-group">
          <div className="class-second-column flex items-center justify-center">
            <ContentLoader width="75" height="32">
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
          <div className="class-third-column flex items-center">
            <ContentLoader width="130" height="40">
              <circle cx="20" cy="20" r="20" />
              <rect x="50" y="10" rx="5" ry="5" width="80" height="20" />
            </ContentLoader>
          </div>
          <div className="class-fourth-column flex items-center justify-center">
            <ContentLoader width="130" height="40">
              <rect x="0" y="0" rx="15" ry="15" width="100%" height="100%" />
            </ContentLoader>
          </div>
        </div>
      </div>
      <Delimiter />
    </>
  );
};

export default ClassRowSkeleton;
