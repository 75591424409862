import React from 'react';

import { Link } from 'react-router-dom';

import { icons } from '../../../../helpers/icons';

interface IReadingMaterialLink {
  text: string;
  link: string;
}

const ReadingMaterialLink: React.FC<IReadingMaterialLink> = ({
  text,
  link,
}) => {
  return (
    <Link to={link} target="_blank" className="flex items-center gap-2 pt-2">
      <div>
        <img src={icons.book} alt="" />
      </div>
      <div className="text-sm font-bold text-grey-700 underline cursor-pointer">
        {text}
      </div>
    </Link>
  );
};

export default ReadingMaterialLink;
