import React from 'react';
import { Link } from 'react-router-dom';

import { ButtonRegular } from '../../buttons/ButtonRegular';
import { NAVIGATE_PATH } from '../../../../helpers/constants';

interface IHeader {
  button: React.ReactNode;
}

export const Header: React.FC<IHeader> = ({ button }) => {
  return (
    <div className="sticky top-0 z-20 items-center py-4 xl:px-0">
      <div className="flex justify-between items-center mx-auto auth-container">
        {button}
        <div className="2xl:flex"></div>
        <div className="2xl:flex items-center space-x-4 md:space-x-6">
          <Link
            to={NAVIGATE_PATH.publicCourses}
            className="text-grey-700 font-medium hover:underline"
          >
            Public Courses
          </Link>
          <Link to={NAVIGATE_PATH.signUp}>
            <ButtonRegular
              className="px-6 font-semibold text-blue-one bg-white-one hover:shadow w-max h-11"
              type={'submit'}
              text="sign up"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};
