import React from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';

import ShortToast from '../../toasts/ShortToast';
import { ReactComponent as CopyIcon } from '../../../../assets/images/copy.svg';

interface ICredentials {
  username: string;
  password: string;
}

const Credentials: React.FC<ICredentials> = ({ username, password }) => {
  const handleCopy = () => {
    toast.custom(
      <ShortToast
        text="Copied to clipboard"
        icon={<CopyIcon fill="#223BF4" />}
      />,
    );
  };

  return (
    <div className="flex items-center gap-[10px] px-2 py-1 rounded-lg border border-solid border-grey-100 text-regular font-medium text-grey-700">
      <div>
        <div>
          <span>Username: </span>
          <span className="font-bold">{username}</span>
        </div>
        <div>
          <span>Password: </span>
          <span className="font-bold">{password}</span>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="cursor-pointer">
          <CopyToClipboard text={username} onCopy={() => handleCopy()}>
            <CopyIcon fill="#BABABA" />
          </CopyToClipboard>
        </div>
        <div className="cursor-pointer">
          <CopyToClipboard text={password} onCopy={() => handleCopy()}>
            <CopyIcon fill="#BABABA" />
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
};

export default Credentials;
