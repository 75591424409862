import React from 'react';

import LoadingImage from '../../../LoadingImage';
import { icons } from '../../../../../helpers/icons';
import { useAppSelector } from '../../../../../hooks/hooks';
import { userAvatar } from '../../../../../helpers';

const UserDropdownToggle: React.FC = () => {
  const { user } = useAppSelector((state) => state.userReducer);

  return (
    <div className="flex gap-2 items-center cursor-pointer">
      <div className="max-lg:hidden">
        <LoadingImage src={userAvatar(user)} className="avatar" />
      </div>
      <div>
        <img className="max-lg:hidden" src={icons.arrowDown} alt="arrowdown" />
        <img className="lg:hidden" src={icons.menu} alt="menu" />
      </div>
    </div>
  );
};

export default UserDropdownToggle;
