import React, { ChangeEventHandler } from 'react';

import { ICompany } from './models/ICompany';
import { InviteType } from '../src/global/types';

export type TValues = {
  [field: string]: string;
};

export type TLoginValues = {
  email: string;
  password: string;
};

export type TSignUpValues = {
  firstName: string;
  lastName: string;
  email: string;
};

export type TResetPasswordValue = {
  email: string;
};

export type TConfirmPasswordValue = {
  password: string;
  confirmPassword: string;
};

export type TPaymentForm = {
  firstName: string;
  lastName: string;
  email: string;
  hasReadTerms: boolean;
};

export type TAgreementValues = {
  hasReadTerms: boolean;
  agreedToPolicy: boolean;
};

export interface IInputCustom {
  handleBlur: ChangeEventHandler<HTMLInputElement>;
}

export type TNavItem = {
  label: string;
  shortLabel?: string;
  path: string;
  items?: TNavItem[];
};

export type TNavTab = {
  header: string;
  description?: string;
  items: TNavItem[];
};

export type TOption = {
  text: string;
  value: any;
};

export type TClassStatus =
  | 'No Attend'
  | 'Booked'
  | 'Transferred'
  | 'Cancelled'
  | 'Completed'
  | 'Attended'
  | null
  | string;

export type TColor =
  | 'base'
  | 'neutral'
  | 'primary'
  | 'danger'
  | 'green'
  | 'yellow'
  | 'red'
  | 'blue';

export type TClass = {
  id: string;
  name: string;
  type: 'Public Class' | 'Private Class';
  description: string;
  status: TClassStatus;
  startTime: string;
  endTime: string;
  companyName: string;
  attended: boolean;
  evaluationLink: string;
  joinLink: string;
  address?: string;
  companyPhoto?: string;
  readingMaterialLink?: string;
  credentials?: {
    roomEmail: string;
    roomPassword: string;
  };
};

export type TLogin = {
  accessToken: string;
  email: string;
  resolvedInvite?: TResolvedInvite;
};

export type TCourseFilters = {
  parentCategories: TNamedItem[];
  categories: TNamedItem[];
  competencies: TNamedItem[];
};

export type TActiveFilters = {
  primaryFilter: TNamedItem | null;
  secondaryFilter: TNamedItem | null;
};

export type TNamedItem = {
  id: string;
  name: string;
};

export type TReview = {
  quote: string;
} & TNamedItem;

export type TCoursePreview = {
  id: string;
  templatePublicId: string;
  name: string;
  slug: string;
  whoShouldAttend: string;
  priceRange?: string;
  nearestStartTime?: string;
  imageUrl: string;
  categoryIds: string[];
  skillIds: string[];
  primaryCompetencyIds: string[];
};

export type TCourse = {
  prerequisites: string;
  outline: string;
  skills: TNamedItem[];
  reviews: TReview[];
} & TCoursePreview;

export type TPrivateCourse = {
  availableTime: Omit<TAvailableTime, 'price'>;
  coursePreview: TCoursePreview;
};

export type TCourseGroup = {
  groupName: string;
  courses: TCoursePreview[];
};

export type TInviteType = 'company' | 'privateClass' | 'program';

export type TResolvedInvite = {
  inviteType: InviteType;
  itemName: string;
};

export type TInviteDetails = {
  company: ICompany | null;
};

export type TInviteRequest = {
  inviteType: TInviteType;
  sfProgramId?: string | null;
  sfLearnitCourseId?: string | null;
};

export type TInviteResponse = {
  resolvedInvite?: TResolvedInvite;
};

export type TAvailableModule = {
  id: string;
  name?: string;
  availableTimes: TAvailableTime[];
};

export type TAvailableTime = {
  id: string;
  name: string;
  price: number;
  startTime: string;
  endTime: string;
  isBooked?: boolean;
};

export type TPublicCartItem = {
  included: boolean;
  studentIds?: string[];
  course: Pick<TCoursePreview, 'name' | 'imageUrl'>;
} & TAvailableTime;

export type TPrivateCartItem = Omit<TPublicCartItem, 'price'>;

export type TCartItem = TPublicCartItem | TPrivateCartItem;

export type TCart = {
  public: TPublicCartItem[];
  private: TPrivateCartItem[];
  processed: TCartItem[];
  status: TCheckoutStatus;
  createdAt: number;
};

export type TCheckoutPayment = {
  items: TPublicCartItem[];
  checkoutStudents: TStudent[] | null;
  guestDetails?: {
    email: string;
    firstName: string;
    lastName: string;
  };
};

export type TInitialCheckoutResult = {
  itemsToPay: TPublicCartItem[];
  processedItems: TCartItem[];
};

export type TStudent = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type TCheckoutStatus = 'open' | 'second_step' | 'completed';

export type TAssignmentUpdate = { itemId: string; studentId: string };

export type TBaseProgram = {
  id: string;
  type: ProgramType;
  startDate: string;
  endDate: string;
};

export type TProgramInviteValues = {
  sfProgramId: string;
  expiresAt: string;
  maxRegistrations: string;
};

export type TProgramInvite = {
  _id: string;
  token: string;
  maxRegistrations: string;
  registrationCount: string;
  expiresAt: string;
  company: ICompany;
  program: { id: string; name: string; type: ProgramType };
};

export type TProgram = {
  publicEnrollmentEnabled: boolean;
  privateClassesEnabled: boolean;
} & TBaseProgram;

export enum ProgramType {
  TEAM_PASS = 'Team Pass',
  TEAM_PASS_PLUS = 'Team Pass Plus',
  PARTNERSHIP = 'Partnership',
  PRIVATE_CLASS_SERIES = 'Private Class Series',
  PRIVATE_SINGLE_CLASS = 'Private Single Class',
  PUBLIC_ENROLLMENT = 'Public Open Enrollment',
  LEARNIT_ANYTIME = 'Learnit Anytime',
}

export type TUploadResponse = {
  url: string;
};

export type ImageLoadEvent = React.SyntheticEvent<HTMLImageElement, Event>;

export type ActionMenuItem = {
  label: string;
  onClick: () => void;
};

export enum ErrorCode {
  EMAIL_TAKEN = 'email_taken',
  LINK_INVALID = 'link_invalid',
  NAME_REQUIRED = 'name_required',
  NOT_FOUND = 'not_found',
  PERMISSION_DENIED = 'permission_denied',
  UNEXPECTED_ERROR = 'unexpected_error',
}
