import React from 'react';

import { Tooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';

import {
  NAVIGATE_PATH as PATH,
  NAVIGATION_TABS as TABS,
} from '../../../../../../helpers/constants';
import NavIcon from './NavIcon';
import { useAppSelector } from '../../../../../../hooks/hooks';
import { UserRole } from '../../../../../../models/IUser';
import { ReactComponent as HelpVideos } from '../../../../../../assets/images/help-videos.svg';
import { ReactComponent as Play } from '../../../../../../assets/images/play.svg';

interface INavIcons {
  activeTab: string;
  setActiveTab: (tabKey: string) => void;
}

const NavIcons: React.FC<INavIcons> = ({ activeTab, setActiveTab }) => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.userReducer);

  const handleClick = (tab: string, defaultPath: string) => {
    setActiveTab(tab);
    navigate(defaultPath);
  };

  return (
    <div className="flex lg:flex-col justify-around lg:justify-start grow shrink-0 bg-white lg:bg-grey-100">
      <NavIcon
        tabKey={TABS.dashboard}
        active={activeTab === TABS.dashboard}
        handleClick={() => handleClick(TABS.dashboard, PATH.upcomingClasses)}
      />
      <NavIcon
        tabKey={TABS.library}
        label="All Courses"
        active={activeTab === TABS.library}
        handleClick={() => handleClick(TABS.library, PATH.publicCourses)}
      />
      <NavIcon
        tabKey={TABS.reports}
        active={activeTab === TABS.reports}
        comingSoon
      />
      <NavIcon
        hidden
        tabKey={TABS.manage}
        active={activeTab === TABS.manage}
        handleClick={() => handleClick(TABS.manage, PATH.managePrograms)}
        comingSoon={user?.role !== UserRole.ADMIN}
      />
      {user?.role === UserRole.MANAGER ? (
        <NavIcon
          tabKey={TABS.settings}
          active={activeTab === TABS.settings}
          handleClick={() => handleClick(TABS.settings, PATH.profileSettings)}
        />
      ) : (
        <NavIcon
          tabKey={TABS.info}
          active={activeTab === TABS.info || activeTab === TABS.settings}
          handleClick={() => handleClick(TABS.info, PATH.profileSettings)}
        />
      )}
      <a
        target="_blank"
        href="https://www.learnit.com/portal-help"
        className="flex flex-col items-center rounded-l-lg cursor-pointer"
        data-tooltip-id="tutorial-tooltip"
        data-tooltip-place="right"
        data-tooltip-html={`<strong>A helpful video on using the new portal</strong>`}
      >
        <div className="py-4 xm:px-4">
          <HelpVideos />
        </div>
        <div className="nav-icon-label">Tutorial</div>
      </a>
      <Tooltip id="tutorial-tooltip" className="tooltip max-w-[12rem]" />
      {user?.program && (
        <a
          target="_blank"
          href="https://learnitanytime.com"
          className="flex flex-col items-center rounded-l-lg cursor-pointer"
        >
          <div className="py-4 xm:px-4">
            <Play />
          </div>
          <div className="nav-icon-label text-center leading-tight">
            LearnIT <br className="max-lg:hidden" />
            Anytime
          </div>
        </a>
      )}
    </div>
  );
};

export default NavIcons;
