import React from 'react';

import MiniTag from '../../../MiniTag';

interface ISummaryQuantity {
  label: string;
  quantity: number;
  booked: boolean;
}

const SummaryQuantity: React.FC<ISummaryQuantity> = ({
  label,
  quantity,
  booked,
}) => {
  if (!quantity) {
    return null;
  }

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-1">
        {booked ? (
          <MiniTag text="Booked" color="green" />
        ) : (
          <MiniTag text="Pending" color="neutral" />
        )}
        <div>{label}</div>
      </div>
      <div>
        x <span className="font-bold">{quantity}</span>
      </div>
    </div>
  );
};

export default SummaryQuantity;
