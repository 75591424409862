import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import NavIcons from './NavIcons';
import NavItem from './NavItem';
import { NAVIGATION_TABS } from '../../../../../helpers/constants';
import { getUserNavigation } from '../../../../../helpers';
import { useAppSelector } from '../../../../../hooks/hooks';

const NavigationTabs = () => {
  const location = useLocation();
  const { user } = useAppSelector((state) => state.userReducer);

  const getTabFromLocation = () => {
    const split = location.pathname.split('/');
    const tab = split.length > 1 ? split[1] : '';
    return NAVIGATION_TABS[tab as keyof typeof NAVIGATION_TABS] ?? activeTab;
  };

  const [activeTab, setActiveTab] = useState('');
  const tabInfo = getUserNavigation(user)[activeTab] ?? {};

  useEffect(() => {
    setActiveTab(getTabFromLocation());
  }, [location]);

  return (
    <div className="flex grow w-full">
      <NavIcons activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="hidden lg:flex w-full pt-4 px-5 bg-grey-50">
        <div key={tabInfo.header} className="flex w-full flex-col gap-4">
          <div className="space-y-2">
            <div className="text-lg font-bold text-neutralgrey-900">
              {tabInfo.header}
            </div>
            {tabInfo.description && (
              <div className="text-xs font-normal text-grey-700">
                {tabInfo.description}
              </div>
            )}
          </div>
          <div>
            {tabInfo.items?.map((item) => {
              return (
                <NavItem key={item.path} path={item.path} label={item.label} />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavigationTabs;
