import React from 'react';

import { Header } from './Header';
import { icons } from '../../../helpers/icons';
import Delimiter from '../Delimiter';

interface IAuthLayout {
  children: React.ReactNode;
  button: React.ReactNode;
  banner?: React.ReactNode;
}

export const AuthLayout: React.FC<IAuthLayout> = ({
  children,
  button,
  banner,
}) => {
  return (
    <div className="flex flex-col h-screen">
      <Header button={button} />
      <div className="auth-container xl:px-28 flex flex-col xl:flex-row-reverse items-center xl:justify-between xl:flex-grow-[0.7]">
        <div className="xl:hidden">{banner}</div>
        <div className={`${banner ? 'mt-10' : 'mt-20'} xl:mt-0 xl:ml-4`}>
          {children}
        </div>
        <div className="mb-4 text-core-dark">
          <div className="hidden xl:block">{banner}</div>
          <div className="mt-8 xl:mt-0 mb-8 flex flex-col items-center xl:items-start">
            <div className="mb-4 text-lg">Live online training and support</div>
            <div className="flex items-center gap-4">
              <img src={icons.smartphone} alt="" />
              <span className="text-[33px] font-bold">415.946.6500</span>
            </div>
          </div>
          <Delimiter className="xl:hidden" />
          <div className="mt-8 flex flex-col items-center xl:items-start">
            <div className="mb-4 text-lg">International toll free</div>
            <div className="flex items-center gap-4">
              <img src={icons.globe} alt="" />
              <span className="text-[33px] font-bold">855.838.5028</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
