import React, { ReactNode } from 'react';

import { Link } from 'react-router-dom';

import CartDropdown from './CartDropdown';
import UserDropdown from './UserDropdown';
import { icons } from '../../../helpers/icons';
import { useAppSelector } from '../../../hooks/hooks';
import { NAVIGATE_PATH } from '../../../helpers/constants';

interface INavHeader {
  label: string | ReactNode;
  action?: ReactNode;
  className?: string;
  showGuestLogin?: boolean;
}

const NavHeader: React.FC<INavHeader> = ({
  label,
  action,
  className,
  showGuestLogin = true,
}) => {
  const { user } = useAppSelector((state) => state.userReducer);

  return (
    <div
      className={`py-2 px-4 lg:pl-6 sticky top-0 z-50 rounded-t-lg bg-white ${className}`}
    >
      <div className="flex justify-between guest:guest-wrapper guest:mx-auto">
        <div className="py-2 pr-2 flex grow gap-2 justify-between items-center">
          <div className="text-heading-dark">{label}</div>
          <div className="flex items-center gap-2">
            {action}
            <div className="hidden text-xs leading-5 underline text-grey-700 cursor-pointer">
              Filter
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex items-center gap-2">
            <div className="hidden">
              <form>
                <div className="relative">
                  <div className="absolute inset-y-0 flex items-center pointer-events-none start-0 ps-4">
                    <img src={icons.search} className="w-4 h-4" alt="search" />
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    className="header-input"
                    placeholder="Search"
                  />
                </div>
              </form>
            </div>
            <div className="hidden shrink-0 cursor-pointer">
              <img src={icons.bell} alt="notification" />
            </div>

            <div className="flex items-center gap-2 max-lg:flex-row-reverse">
              {user && (
                <>
                  <UserDropdown />
                  <div className="h-8 border-r" />
                </>
              )}
              <CartDropdown />
              {!user && showGuestLogin && (
                <>
                  <div className="h-8 border-r" />
                  <Link
                    to={NAVIGATE_PATH.login}
                    className="text-regular text-core-dark font-medium hover:underline"
                  >
                    Login
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavHeader;
