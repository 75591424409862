import React from 'react';

import { Link } from 'react-router-dom';

import { LEARNIT_URL } from '../../../../helpers/constants';

const ContactUs = () => {
  return (
    <Link to={`${LEARNIT_URL}/contact`} target="_blank">
      <span className="text-blue-primary font-bold"> contact us </span>
    </Link>
  );
};

export default ContactUs;
