import { TProgram } from '../types';
import { ICompany } from './ICompany';

export interface IUser {
  email: string;
  firstName: string;
  lastName: string;
  photo?: string;
  role: UserRole;
  company: ICompany;
  individual: boolean;
  program: TProgram | null;
}

export enum UserRole {
  STUDENT = 'student',
  INSTRUCTOR = 'instructor',
  MANAGER = 'manager',
  ADMIN = 'admin',
}
