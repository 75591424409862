import React from 'react';
import { NavLink } from 'react-router-dom';

// import LogoIcon from '../../../../assets/images/Learnit_logo.svg';
import { NAVIGATE_PATH } from '../../../../helpers/constants';

interface ILogoButton {
  className: string;
  logo: string;
}

export const LogoButton: React.FC<Partial<ILogoButton>> = ({
  className = '',
  logo,
}) => {
  return (
    <NavLink to={NAVIGATE_PATH.home}>
      <img className={`z-20 ${className}`} src={logo} alt="logo" />
    </NavLink>
  );
};
