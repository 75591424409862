import React from 'react';

import StudentSelect from '../../../../StudentSelect';
import { useAppDispatch } from '../../../../../../../hooks/hooks';
import { TCartItem, TStudent } from '../../../../../../../types';
import { icons } from '../../../../../../../helpers/icons';
import { toggleClassAssignment } from '../../../../../../../store/reducers/userSlice';

interface ICheckoutItemAssignment {
  item: TCartItem;
  students: TStudent[];
}

const CheckoutItemAssignment: React.FC<ICheckoutItemAssignment> = ({
  item,
  students,
}) => {
  const dispatch = useAppDispatch();

  const availableStudents = students.filter(
    (s) => s.email && !item.studentIds?.some((selected) => selected === s.id),
  );

  const toggleStudent = (studentId: string) => {
    if (studentId) {
      dispatch(
        toggleClassAssignment({
          itemId: item.id,
          studentId,
        }),
      );
    }
  };

  if (students.length === 1) {
    return null;
  }

  return (
    <div className="2xl:mt-4 flex gap-4 justify-between items-center">
      <span className="text-blue-primary text-regular font-medium flex-shrink-0">
        Assign to:
      </span>
      <div className="flex grow gap-2 flex-wrap items-center">
        {students
          .filter((student) => item.studentIds?.includes(student.id))
          .map((student) => (
            <div
              key={student.id}
              className="px-2 py-1 relative flex gap-2 items-center border border-1 border-grey-500 rounded-3xl"
            >
              {student.email ? (
                <span className="mr-6 text-regular text-core-dark font-medium max-w-[14rem] sm:max-w-[18rem] truncate">
                  {student.email}
                </span>
              ) : (
                <span className="mr-6 text-regular text-red-700 font-medium">
                  Invalid email address
                </span>
              )}
              <img
                className="p-2 absolute end-0 cursor-pointer"
                src={icons.close}
                alt=""
                onClick={() => toggleStudent(student.id)}
              />
            </div>
          ))}
        {availableStudents.length > 0 && (
          <StudentSelect
            toggleStudent={toggleStudent}
            availableStudents={availableStudents}
          />
        )}
      </div>
    </div>
  );
};

export default CheckoutItemAssignment;
