import React, { useEffect, useState } from 'react';

import ContentLoader from 'react-content-loader';
import { useParams } from 'react-router-dom';

import BackButton from '../../../components/common/buttons/BackButton';
import CourseAvailableTimes from '../../../components/common/library/course/CourseAvailableTimes';
import CourseDetails from '../../../components/common/library/course/CourseDetails';
import CourseOutline from '../../../components/common/library/course/CourseOutline';
import CourseReviews from '../../../components/common/library/course/CourseReviews';
import NavHeader from '../../../components/common/NavHeader';
import NotFound from '../../NotFound';
import { TCourse } from '../../../types';
import { getPublicCourse } from '../../../requests';
import { NAVIGATE_PATH } from '../../../helpers/constants';

const PublicCourse: React.FC = () => {
  const { slug } = useParams();
  const [course, setCourse] = useState<TCourse>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPublicCourse(slug)
      .then((course) => setCourse(course))
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <NavHeader
        label={<BackButton defaultRoute={NAVIGATE_PATH.publicCourses} />}
      />
      {loading ? (
        <div className="main-container h-screen pt-8 bg-white">
          <div className="w-full guest-wrapper guest:mx-auto">
            <ContentLoader className="w-full xl:w-[70%] h-96">
              <rect x="0" y="0" rx="10" ry="10" width="100" height="100" />
              <rect x="0" y="116" rx="5" ry="5" width="300" height="56" />
              <rect x="0" y="188" rx="5" ry="5" width="100%" height="150" />
            </ContentLoader>
          </div>
        </div>
      ) : course ? (
        <div className="min-h-[calc(100vh-114px)] xl:min-h-[calc(100vh-60px)] flex flex-col bg-grey-50">
          <div className="px-4 lg:px-8 pt-8 pb-10 bg-white">
            <div className="w-full guest:guest-wrapper mx-auto flex flex-col 2xl:flex-row gap-6 lg:guest:flex-row">
              <div className="flex grow">
                <CourseDetails course={course} />
              </div>
              <div className="lg:min-w-[35%]">
                <CourseAvailableTimes course={course} />
              </div>
            </div>
          </div>
          <CourseOutline course={course} />
          <CourseReviews reviews={course.reviews} />
        </div>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default PublicCourse;
