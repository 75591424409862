import React, { useRef, useState } from 'react';

import { uploadCompanyLogo, uploadUserAvatar } from '../../../../requests';
import { useAppDispatch } from '../../../../hooks/hooks';
import {
  setCompanyLogo,
  setUserAvatar,
} from '../../../../store/reducers/userSlice';
import ImageCropModal from '../../modals/ImageCropModal';
import { showToast } from '../../../../helpers';
import { icons } from '../../../../helpers/icons';

interface IImageControl {
  src?: string;
  type: 'user' | 'company';
  editable?: boolean;
}

const ImageControl: React.FC<IImageControl> = ({
  src,
  type,
  editable = true,
}) => {
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const uploadImage = async (image: Blob | null) => {
    try {
      switch (type) {
        case 'user':
          const avatar = await uploadUserAvatar(image);
          dispatch(setUserAvatar(avatar.url));
          break;
        case 'company':
          const logo = await uploadCompanyLogo(image);
          dispatch(setCompanyLogo(logo.url));
          break;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleUpload = () => {
    fileInputRef?.current?.click();
  };

  const handleRemove = () => {
    const confirmed = confirm('Image will be removed. Are you sure?');
    if (confirmed) {
      void uploadImage(null);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    if (
      file.type !== 'image/jpeg' &&
      file.type !== 'image/webp' &&
      file.type !== 'image/png'
    ) {
      return showToast(
        'Invalid file format. Please select a JPEG, WEBP, or PNG image.',
        'error',
      );
    }
    setFile(file);
  };

  return (
    <div className="flex gap-4 items-center">
      <div className="w-[6.25rem] h-[6.25rem] rounded-lg border-1 border-grey-100">
        <img
          className="w-full h-full object-cover rounded-md"
          src={
            src
              ? src
              : type === 'company'
              ? icons.companyPlaceholder
              : icons.avatarPlaceholder
          }
          alt=""
        />
      </div>
      {editable && (
        <>
          <div className="underline-link" onClick={handleUpload}>
            Upload
          </div>
          {src && (
            <div className="underline-link text-red-900" onClick={handleRemove}>
              Remove
            </div>
          )}
          <div>
            <input
              type="file"
              accept="image/*"
              className="hidden"
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </div>
          {file && (
            <ImageCropModal
              file={file}
              isOpen={isModalOpen}
              setIsOpen={setIsModalOpen}
              uploadImage={uploadImage}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ImageControl;
