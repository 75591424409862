import React, { ReactNode } from 'react';

interface IDropdown {
  toggle: ReactNode;
  children: ReactNode;
  className?: string;
  maxHeight?: string;
  isOpen: boolean;
  dropdownRef: React.RefObject<HTMLDivElement>;
  handleDropdownToggle: () => void;
}

const Dropdown: React.FC<IDropdown> = ({
  toggle,
  children,
  className = 'bg-white rounded-lg',
  maxHeight = '14rem',
  isOpen,
  dropdownRef,
  handleDropdownToggle,
}) => {
  return (
    <div ref={dropdownRef} className="relative shrink-0">
      <div onClick={handleDropdownToggle}>{toggle}</div>
      <div
        className={`max-h-0 mt-4 -end-2 overflow-hidden absolute z-20 shadow-light 
        transition-max-height ease-in-out duration-300 ${className}`}
        style={{
          maxHeight: isOpen ? maxHeight : '0px',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Dropdown;
