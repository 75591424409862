import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';

import { NAVIGATE_PATH as PATH } from '../helpers/constants';
import { SetPassword } from '../views/Auth/SetPassword';
import { Login } from '../views/Auth/Login';
import { ResetPassword } from '../views/Auth/ResetPassword';
import { SignUp } from '../views/Auth/SignUp';
import Invite from '../views/Auth/Invite';
import PastClasses from '../views/Dashboard/PastClasses';
import UpcomingClasses from '../views/Dashboard/UpcomingClasses';
import PrivateCourses from '../views/Library/PrivateCourses';
import PublicCourses from '../views/Library/PublicCourses';
import CompanySettings from '../views/Settings/CompanySettings';
import { NavbarLayout } from './common/NavbarLayout';
import PublicCourse from '../views/Library/PublicCourse';
import CheckoutSummary from '../views/Checkout/CheckoutSummary';
import FirstCheckoutStep from '../views/Checkout/FirstCheckoutStep';
import CheckoutThanks from '../views/Checkout/CheckoutThanks';
import ProfileSettings from '../views/Settings/ProfileSettings';
import SecondCheckoutStep from '../views/Checkout/SecondCheckoutStep';
import AnonymousRoute from './routes/AnonymousRoute';
import RootRoute from './routes/RootRoute';
import CheckoutLogin from '../views/Checkout/CheckoutLogin';
import CheckoutGuest from '../views/Checkout/CheckoutGuest';
import ProgramInvites from '../views/Manage/ProgramInvites';
// import Calendar from '../views/Library/Calendar';
// import PendingInvites from '../views/Members/PendingInvites';
// import TeamMembers from '../views/Members/TeamMembers';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootRoute />}>
      <Route path={PATH.setPassword} element={<SetPassword />} />
      <Route element={<AnonymousRoute />}>
        <Route path={PATH.login} element={<Login />} />
        <Route path={PATH.signUp} element={<SignUp />} />
        <Route path={PATH.inviteParam} element={<SignUp />} />
        <Route path={PATH.resetPassword} element={<ResetPassword />} />
        <Route path={PATH.createPassword} element={<ResetPassword create />} />
        <Route path={PATH.checkoutLogin} element={<CheckoutLogin />} />
      </Route>
      <Route element={<NavbarLayout />}>
        <Route path={PATH.inviteQuery} element={<Invite />} />
        <Route path={PATH.upcomingClasses} element={<UpcomingClasses />} />
        <Route path={PATH.pastClasses} element={<PastClasses />} />
        <Route path={PATH.publicCourses} element={<PublicCourses />} />
        <Route path={PATH.publicCourse} element={<PublicCourse />} />
        <Route path={PATH.privateCourses} element={<PrivateCourses />} />
        <Route path={PATH.profileSettings} element={<ProfileSettings />} />
        <Route path={PATH.companySettings} element={<CompanySettings />} />
        <Route path={PATH.companyInfo} element={<CompanySettings />} />
        <Route path={PATH.managePrograms} element={<ProgramInvites />} />
        <Route path={PATH.checkoutSummary} element={<CheckoutSummary />} />
        <Route path={PATH.checkoutGuest} element={<CheckoutGuest />} />
        <Route path={PATH.checkoutThanks} element={<CheckoutThanks />} />
        <Route path={PATH.firstCheckoutStep} element={<FirstCheckoutStep />} />
        <Route
          path={PATH.secondCheckoutStep}
          element={<SecondCheckoutStep />}
        />
        {/*<Route path={PATH.calendar} element={<Calendar />} />*/}
        {/*<Route path={PATH.teamMembers} element={<TeamMembers />} />*/}
        {/*<Route path={PATH.pendingInvites} element={<PendingInvites />} />*/}
      </Route>
      <Route path={PATH.any} element={<Navigate to={PATH.upcomingClasses} />} />
    </Route>,
  ),
);

export const Routes = () => {
  return <RouterProvider router={router} />;
};
