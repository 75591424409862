import React from 'react';

interface IClassAvatar {
  name: string;
  photo?: string;
}

const ClassAvatar: React.FC<IClassAvatar> = ({ name, photo }) => {
  return (
    name && (
      <div className="flex gap-2 items-center">
        {photo && (
          <div className="shrink-0 rounded-full">
            <img className="w-8 h-8 rounded-full" src={photo} alt="" />
          </div>
        )}
        <div
          className="2xl:inline-block 2xl:max-w-[8rem] 3xl:max-w-[12rem] text-regular font-medium text-grey-700 2xl:whitespace-nowrap text-ellipsis line-clamp-1"
          title={name}
        >
          {name}
        </div>
      </div>
    )
  );
};

export default ClassAvatar;
