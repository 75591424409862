import React from 'react';

import { Link } from 'react-router-dom';
import { differenceInMinutes, isPast } from 'date-fns';

import { icons } from '../../../../helpers/icons';
import { ButtonRegular } from '../../buttons/ButtonRegular';
import { markAttendanceForClass } from '../../../../requests';
import { TClass } from '../../../../types';

interface IClassLinkAddress {
  classInfo: TClass;
}

const ClassLinkAddress: React.FC<IClassLinkAddress> = ({ classInfo }) => {
  const { address, startTime, joinLink, status } = classInfo;
  const handleJoinClass = () => {
    if (!classInfo.attended) {
      markAttendanceForClass(classInfo.id)
        .then(() => (classInfo.attended = true))
        .catch((error) => console.error(error));
    }
  };

  const isLinkAvailable = () => {
    if (status === 'Cancelled') {
      return false;
    }

    const startDate = new Date(startTime);
    return (
      isPast(startDate) ||
      Math.abs(differenceInMinutes(new Date(), startDate)) < 60
    );
  };

  return (
    <>
      {address && !joinLink ? (
        <div className="w-[214px] flex items-center gap-3 text-regular">
          <div className="shrink-0">
            <img src={icons.location} alt="location" />
          </div>
          <div className="font-medium">{address}</div>
        </div>
      ) : joinLink && isLinkAvailable() ? (
        <Link to={joinLink} target="_blank" onClick={handleJoinClass}>
          <ButtonRegular className="button-primary" text="Join class" />
        </Link>
      ) : (
        <div className="xl:w-48 flex flex-col justify-center items-start xl:items-center">
          <ButtonRegular
            className="button-primary"
            text="Join class"
            disabled
          />
          <div className="text-xs xl:text-center font-medium text-grey-700">
            Link will be made available on day of the class
          </div>
        </div>
      )}
    </>
  );
};

export default ClassLinkAddress;
