import React, { useState } from 'react';

import {
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';

import LoaderButton from '../../buttons/LoaderButton';
import { FormInput } from '../../forms/FormsInput';
import { showToast } from '../../../../helpers';
import { useAppSelector } from '../../../../hooks/hooks';
import { TPaymentForm } from '../../../../types';
import {
  DEFAULT_ERROR_MESSAGE,
  LEARNIT_URL,
  NAVIGATE_PATH,
} from '../../../../helpers/constants';
import { createPaymentIntent } from '../../../../requests';

const PaymentForm: React.FC = () => {
  const { checkoutStudents } = useAppSelector((state) => state.checkoutReducer);
  const { user, cart } = useAppSelector((state) => state.userReducer);
  const [showFormError, setShowFormError] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (values: TPaymentForm) => {
    setShowFormError(false);
    if (!stripe || !elements) {
      return;
    }
    const { error } = await elements.submit();
    if (error?.message) {
      return;
    }
    if (!values.hasReadTerms) {
      return setShowFormError(true);
    }

    try {
      const { clientSecret } = await createPaymentIntent({
        items: cart.public,
        checkoutStudents,
        guestDetails: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
        },
      });
      const fullName = user
        ? `${user.firstName} ${user.lastName}`
        : `${values.firstName} ${values.lastName}`;
      const result = await stripe.confirmPayment({
        elements,
        clientSecret,
        redirect: 'always',
        confirmParams: {
          return_url:
            process.env.REACT_APP_WEB_URL + NAVIGATE_PATH.checkoutThanks,
          payment_method_data: {
            billing_details: {
              name: fullName,
              email: user?.email ?? values.email,
            },
          },
        },
      });
      if (result.error) {
        showToast(result.error.message ?? DEFAULT_ERROR_MESSAGE, 'error');
      }
    } catch {
      showToast(DEFAULT_ERROR_MESSAGE, 'error');
    }
  };

  // prefill stripe values if there is only one student
  const individual =
    checkoutStudents?.length === 1 ? checkoutStudents[0] : null;
  const formik = useFormik({
    initialValues: {
      email: individual?.email ?? '',
      firstName: individual?.firstName ?? '',
      lastName: individual?.lastName ?? '',
      hasReadTerms: false,
    },
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
      {!user && (
        <div className="flex justify-between gap-4 [&_label]:stripe-label [&_input]:stripe-input">
          <FormInput
            className="grow"
            label="First name"
            placeholder="First name"
            name={'firstName'}
            value={formik.values.firstName}
            handleChange={formik.handleChange}
          />
          <FormInput
            className="grow"
            label="Last name"
            placeholder="Last name"
            name={'lastName'}
            value={formik.values.lastName}
            handleChange={formik.handleChange}
          />
        </div>
      )}
      <LinkAuthenticationElement
        options={{
          defaultValues: { email: user?.email || individual?.email || '' },
        }}
        onChange={(e) => (formik.values.email = e.value.email)}
      />
      <PaymentElement />
      <div className="flex gap-2 items-center">
        <div>
          <input
            type="checkbox"
            name="hasReadTerms"
            className="checkbox"
            onChange={formik.handleChange}
          />
        </div>
        <div>
          I have read the{' '}
          <Link
            to={`${LEARNIT_URL}/terms-cancellation-policy`}
            target="_blank"
            className="link"
          >
            terms and conditions
          </Link>
        </div>
      </div>
      <div className="flex flex-col gap-2 items-end">
        <LoaderButton
          text="Pay now & finalize booking"
          isLoading={formik.isSubmitting}
          className="button-primary w-72"
        />
        {showFormError && (
          <div className="text-xs text-red-900">
            Please agree to the to necessary terms in order to proceed
          </div>
        )}
      </div>
    </form>
  );
};

export default PaymentForm;
