import React, { useState } from 'react';

import { Navigate, useNavigate } from 'react-router-dom';

import BackButton from '../../../components/common/buttons/BackButton';
import NavHeader from '../../../components/common/NavHeader';
import { ButtonRegular } from '../../../components/common/buttons/ButtonRegular';
import { LoginForm } from '../../../components/forms/LoginForm';
import { useAppSelector } from '../../../hooks/hooks';
import { NAVIGATE_PATH } from '../../../helpers/constants';
import { icons } from '../../../helpers/icons';

const CheckoutLogin: React.FC = () => {
  const navigate = useNavigate();
  const { user, cart } = useAppSelector((state) => state.userReducer);
  const [emailSent, setEmailSent] = useState(false);

  if (user) {
    return <Navigate to={NAVIGATE_PATH.checkoutSummary} replace />;
  }

  if (!cart.public.length) {
    return <Navigate to={NAVIGATE_PATH.publicCourses} replace />;
  }

  return (
    <div className="guest-wrapper max-lg:h-screen m-auto min-h-screen flex flex-col">
      <NavHeader
        label={<BackButton defaultRoute={NAVIGATE_PATH.publicCourses} />}
        className="!bg-grey-100"
        showGuestLogin={false}
      />
      <div className="h-full flex items-center justify-between">
        <div className="mx-auto px-4 lg:py-32 flex grow max-lg:flex-col max-lg:items-center justify-between gap-8">
          <LoginForm
            className="w-full m-0 max-lg:py-10 "
            header="Log in and check out"
            setEmailSent={setEmailSent}
            isCheckoutLogin
          />
          {!emailSent && (
            <div className="w-full m-0 form-container max-lg:p-4 lg:min-h-full grow grid gap-2">
              <div className="flex grow lg:flex-col-reverse items-center justify-center lg:justify-between">
                <img
                  src={icons.holdingBooksIllustration}
                  className="max-lg:w-16"
                  alt=""
                />
                <div className="form-title max-lg:text-xl max-lg:w-auto m-0">
                  Check out as a guest
                </div>
              </div>
              <ButtonRegular
                text="Checkout as a guest"
                className="button-primary m-auto"
                onClick={() => navigate(NAVIGATE_PATH.checkoutGuest)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckoutLogin;
