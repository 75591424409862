import React from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';

import ActionMenu from '../../common/ActionMenu';
import ShortToast from '../../common/toasts/ShortToast';
import MiniTag from '../../common/MiniTag';
import LoadingImage from '../../common/LoadingImage';
import { TProgramInvite } from '../../../types';
import { deleteInvite } from '../../../requests';
import { companyLogo, formatToDate, showToast } from '../../../helpers';
import { DEFAULT_ERROR_MESSAGE, WEB_URL } from '../../../helpers/constants';
import { icons } from '../../../helpers/icons';
import { ReactComponent as CopyIcon } from '../../../assets/images/copy.svg';

interface IProgramInviteRow {
  invite: TProgramInvite;
  onRevoke: (inviteId: string) => void;
}

const ProgramInviteRow: React.FC<IProgramInviteRow> = ({
  invite,
  onRevoke,
}) => {
  const handleCopy = () => {
    toast.custom(
      <ShortToast
        text="Copied to clipboard"
        icon={<CopyIcon fill="#223BF4" />}
      />,
    );
  };

  const handleRevoke = () => {
    deleteInvite(invite._id)
      .then(() => onRevoke(invite._id))
      .catch(() => showToast(DEFAULT_ERROR_MESSAGE, 'error'));
  };

  return (
    <div className="px-1 xl:px-6 py-2 flex gap-1">
      <div className="w-12 shrink-0">
        <LoadingImage
          src={companyLogo(invite.company)}
          className="w-12 h-12 rounded-full"
        />
      </div>
      <div className="flex-grow grid grid-cols-12 xl:grid-cols-[repeat(32,_minmax(0,_1fr))] gap-x-2 items-center">
        <div className="col-span-12 xl:col-span-10 flex gap-1 max-xl:flex-wrap xl:justify-between">
          <div className="flex flex-col h-full">
            <p className="text-main">{invite.company.name}</p>
            <p className="text-xs text-grey-500 font-medium">
              {invite.program.name}
            </p>
          </div>
          <div className="justify-self-end">
            <MiniTag text={invite.program.type} color="neutral" />
          </div>
        </div>
        <p className="col-span-6 xl:col-span-4 2xl:col-span-5 text-main text-left xl:text-right">
          {formatToDate(invite.expiresAt, { weekday: undefined })}
        </p>
        <p className="col-span-6 xl:col-span-3 2xl:col-span-5 text-main font-normal text-right xl:text-center">
          {invite.maxRegistrations ? (
            <span>
              <strong>{invite.registrationCount}/</strong>
              {invite.maxRegistrations}
            </span>
          ) : (
            <strong>{invite.registrationCount}</strong>
          )}
        </p>
        <span className="col-span-10 sm:col-span-7 xl:col-span-11 2xl:col-span-9 text-main overflow-ellipsis overflow-hidden whitespace-nowrap">
          {`${WEB_URL}/invite/${invite.token}`}
        </span>
        <div className="col-span-1 sm:col-span-3 xl:col-span-2">
          <CopyToClipboard
            text={`${WEB_URL}/invite/${invite.token}`}
            onCopy={() => handleCopy()}
          >
            <CopyIcon
              width="1.5rem"
              height="1.5rem"
              fill="#4A4A68"
              className="cursor-pointer"
            />
          </CopyToClipboard>
        </div>
        <div className="col-span-1 sm:col-span-2 xl:col-span-2 2xl:col-span-1 flex justify-end shrink-0">
          <ActionMenu
            button={<img src={icons.options} alt="" className="shrink-0" />}
            items={[
              {
                label: 'Revoke',
                onClick: handleRevoke,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default ProgramInviteRow;
