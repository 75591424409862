import React, { useState } from 'react';

import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';

import LoaderButton from '../../common/buttons/LoaderButton';
import { LEARNIT_URL, NAVIGATE_PATH } from '../../../helpers/constants';
import { TAgreementValues } from '../../../types';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { showToast } from '../../../helpers';
import { checkout } from '../../../requests';
import { completeFirstCheckoutStep } from '../../../store/reducers/userSlice';

const CheckoutAgreementForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { cart, cartTotalToPay } = useAppSelector((state) => state.userReducer);
  const [showFormError, setShowFormError] = useState(false);

  const handleSubmit = async (values: TAgreementValues) => {
    setShowFormError(false);
    if (!(values.hasReadTerms && values.agreedToPolicy)) {
      setShowFormError(true);
      return;
    }
    try {
      const checkoutResult = await checkout(cart);
      dispatch(completeFirstCheckoutStep(checkoutResult));
      if (checkoutResult.itemsToPay.length) {
        navigate(NAVIGATE_PATH.secondCheckoutStep, { replace: true });
      } else {
        navigate(NAVIGATE_PATH.checkoutThanks, { replace: true });
      }
    } catch (error: any) {
      const message = error.response?.data?.message;
      if (message) {
        showToast(message, 'error');
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      hasReadTerms: false,
      agreedToPolicy: false,
    },
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
      <div className="flex gap-2 items-center">
        <div>
          <input
            type="checkbox"
            name="hasReadTerms"
            className="checkbox"
            onChange={formik.handleChange}
          />
        </div>
        <div>
          I have read the{' '}
          <Link
            to={`${LEARNIT_URL}/terms-cancellation-policy`}
            target="_blank"
            className="link"
          >
            terms and conditions
          </Link>
        </div>
      </div>
      <div className="flex gap-2 items-center">
        <div>
          <input
            type="checkbox"
            name="agreedToPolicy"
            className="checkbox"
            onChange={formik.handleChange}
          />
        </div>
        <div>I agree to the Terms of Use, Refund Policy & Privacy Policy</div>
      </div>
      <div className="flex flex-col gap-2 items-end">
        <LoaderButton
          text={
            cartTotalToPay > 0
              ? 'Book included classes & continue'
              : 'Proceed to finalize booking'
          }
          isLoading={formik.isSubmitting}
          className="button-primary w-80"
        />
        {showFormError && (
          <div className="text-xs text-red-900">
            Please agree to the to necessary terms in order to proceed
          </div>
        )}
      </div>
    </form>
  );
};

export default CheckoutAgreementForm;
