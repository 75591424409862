import React, { ReactNode } from 'react';

import { icons } from '../../../helpers/icons';

interface IModal {
  title: string;
  onClose: () => void;
  children: ReactNode;
}

const Modal: React.FC<IModal> = ({ title, onClose, children }) => {
  return (
    <div className="p-4 sm:w-[26rem] max-h-screen flex flex-col gap-2 bg-white rounded-2xl">
      <div className="relative flex justify-between items-center">
        <div className="text-lg text-core-dark font-bold">{title}</div>
        <div
          className="shrink-0 cursor-pointer p-2 absolute -end-2"
          onClick={onClose}
        >
          <img src={icons.close} alt="close" />
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default Modal;
