import React from 'react';
import { useAppSelector } from '../../../../hooks/hooks';
import { UserRole } from '../../../../models/IUser';

interface IClassesHeader {
  lastRowHeader: string;
}

const ClassesHeader: React.FC<IClassesHeader> = ({ lastRowHeader }) => {
  const { user } = useAppSelector((state) => state.userReducer);

  return (
    <div className="hidden 2xl:flex px-4 lg:px-8 gap-2 justify-between leading-5">
      <div className="class-first-column table-header text-start">Course</div>
      <div className="flex w-full justify-evenly gap-2">
        <div className="class-second-column table-header">Status</div>
        <div className="class-third-column table-header text-start">
          {user?.role === UserRole.INSTRUCTOR ? 'Company' : 'Type'}
        </div>
        <div className="class-fourth-column table-header">{lastRowHeader}</div>
      </div>
    </div>
  );
};

export default ClassesHeader;
