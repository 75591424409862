import React, { useEffect } from 'react';

import { Toaster } from 'react-hot-toast';
import { Outlet, useLocation } from 'react-router-dom';

import ToastBar from '../../common/toasts/ToastBar';

const RootRoute: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <>
      <Outlet />
      <Toaster>{(toast) => <ToastBar newToast={toast}></ToastBar>}</Toaster>
    </>
  );
};

export default RootRoute;
