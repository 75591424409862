import React from 'react';

import { icons } from '../../../../helpers/icons';
import ContactUs from '../ContactUs';

interface IGetInvitationBanner {
  isValid: boolean;
}

const GetInvitationBanner: React.FC<IGetInvitationBanner> = ({ isValid }) => {
  const title = isValid
    ? 'Get invited to the Learnit Portal'
    : 'Invitation Link Expired';

  const description = isValid
    ? 'It looks like you might need to be invited by your company representative in order to get access to the Learnit Portal.'
    : 'The invitation link you used has either expired or the maximum number of users has been reached. Please request a new invitation from your company representative.';

  return (
    <div className="banner-container">
      <div className="flex items-center gap-2">
        <div className="shrink-0">
          <img src={icons.sittingIllustration} alt="" />
        </div>
        <div className="title-calistoga">{title}</div>
      </div>
      <div>{description}</div>
      <div>
        If you need further help please
        <ContactUs />
      </div>
    </div>
  );
};

export default GetInvitationBanner;
