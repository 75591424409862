import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TStudent } from '../../types';

interface CheckoutState {
  checkoutStudents: TStudent[] | null;
}

const initialState: CheckoutState = {
  checkoutStudents: null,
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setCheckoutStudents(state, { payload }: PayloadAction<TStudent[]>) {
      state.checkoutStudents = payload;
    },
  },
});

export const { setCheckoutStudents } = checkoutSlice.actions;
export default checkoutSlice.reducer;
