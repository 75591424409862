import React, { Fragment, useEffect, useState } from 'react';

import { Navigate } from 'react-router-dom';

import ActionLink from '../../../components/common/ActionLink';
import Delimiter from '../../../components/common/Delimiter';
import NavHeader from '../../../components/common/NavHeader';
import SubNavigation from '../../../components/common/SubNavigation';
import ProgramInviteRow from '../../../components/manage/ProgramInviteRow';
import ProgramInvitesSidebar from '../../../components/manage/ProgramInvitesSidebar';
import { useAppSelector } from '../../../hooks/hooks';
import { UserRole } from '../../../models/IUser';
import { TProgramInvite } from '../../../types';
import { NAVIGATE_PATH } from '../../../helpers/constants';
import { getProgramInvites } from '../../../requests';
import { getUserNavigation } from '../../../helpers';

const ProgramInvites: React.FC = () => {
  const { user } = useAppSelector((state) => state.userReducer);
  const [invites, setInvites] = useState<TProgramInvite[]>([]);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    getProgramInvites()
      .then((data) => setInvites(data))
      .catch((e) => console.error(e));
  }, []);

  if (user?.role !== UserRole.ADMIN) {
    return <Navigate to={NAVIGATE_PATH.any} replace />;
  }

  const handleNewInvite = (invite: TProgramInvite) => {
    setInvites([invite, ...invites]);
    setSidebarOpen(false);
  };

  const onRevoke = (inviteId: string) => {
    setInvites(invites.filter(({ _id }) => _id !== inviteId));
  };

  const navigationItems = getUserNavigation(user).manage.items;

  return (
    <>
      <NavHeader
        label="Program Invites"
        action={
          <ActionLink
            prefix="+"
            text="Generate invite link"
            onClick={() => setSidebarOpen(true)}
          />
        }
      />
      {navigationItems.length > 1 && <SubNavigation items={navigationItems} />}
      <div className="flex flex-col min-h-screen">
        <div className="flex flex-col">
          <div className="px-6 py-4 max-xl:hidden flex gap-1 text-sm text-core-dark uppercase">
            <div className="min-w-[3rem]"></div>
            <div className="flex-grow grid grid-cols-[repeat(32,_minmax(0,_1fr))] gap-x-2">
              <div className="col-span-8 2xl:col-span-10">Name</div>
              <div className="col-span-5 text-right">Valid To</div>
              <div className="col-span-5 text-center">Use by</div>
              <div className="col-span-8">Link</div>
              <div className="col-span-1"></div>
            </div>
          </div>
          <Delimiter className="max-xl:hidden" />
          {invites.map((invite) => (
            <Fragment key={invite._id}>
              <ProgramInviteRow invite={invite} onRevoke={onRevoke} />
              <Delimiter className="my-2" />
            </Fragment>
          ))}
        </div>
        <ProgramInvitesSidebar
          isSidebarOpen={isSidebarOpen}
          setSidebarOpen={setSidebarOpen}
          onNewInvite={handleNewInvite}
        />
      </div>
    </>
  );
};

export default ProgramInvites;
