import React from 'react';

import ContentLoader from 'react-content-loader';

import Delimiter from '../../Delimiter';

const CourseGroupSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col">
      <div className="w-full flex items-center gap-10">
        <ContentLoader className="max-h-8">
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="32" />
        </ContentLoader>
        <Delimiter className="w-full mr-5 bg-grey-200" />
      </div>
      <div className="py-10 flex flex-wrap gap-6">
        <ContentLoader className="course-card min-h-[20rem]">
          <rect x="0" y="0" rx="5" ry="0" width="100%" height="160" />
          <rect x="16" y="180" rx="5" ry="5" width="60%" height="28" />
          <rect x="16" y="228" rx="5" ry="5" width="88%" height="65" />
        </ContentLoader>
      </div>
    </div>
  );
};

export default CourseGroupSkeleton;
