import React, { useEffect, useState } from 'react';

import ContentLoader from 'react-content-loader';
import { useSearchParams } from 'react-router-dom';

import Tag from '../../../Tag';
import AvailableTimeItem from '../AvailableTimeItem';
import { icons } from '../../../../../helpers/icons';
import { getCourseAvailableTimes } from '../../../../../requests';
import {
  TAvailableModule,
  TAvailableTime,
  TCourse,
} from '../../../../../types';
import {
  addPublicCourse,
  setIsCartOpen,
  togglePublicCourse,
} from '../../../../../store/reducers/userSlice';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import { isClassSubscriptionIncluded } from '../../../../../helpers';

interface ICourseAvailableTimes {
  course: TCourse;
}

const CourseAvailableTimes: React.FC<ICourseAvailableTimes> = ({ course }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const itemId = searchParams.get('itemId');

  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userReducer);
  const [loading, setLoading] = useState(true);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [availableModules, setAvailableModules] = useState<TAvailableModule[]>(
    [],
  );

  useEffect(() => {
    getCourseAvailableTimes(course.templatePublicId)
      .then((availableModules) => {
        setAvailableModules(availableModules);
        if (itemId) {
          availableModules.forEach((module, index) => {
            const item = module.availableTimes.find(({ id }) => id === itemId);
            if (item && !item.isBooked) {
              setActiveTabIndex(index);
              dispatch(addPublicCourse(mapToCartItem(item)));
              dispatch(setIsCartOpen(true));
            }
          });
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false);
        setSearchParams(undefined);
      });
  }, []);

  const handleItemClick = (availableTime: TAvailableTime) => {
    dispatch(togglePublicCourse(mapToCartItem(availableTime)));
    dispatch(setIsCartOpen(true));
  };

  const mapToCartItem = (availableTime: TAvailableTime) => {
    const { imageUrl } = course;
    return {
      ...availableTime,
      course: { name: availableTime.name, imageUrl },
      included: isClassSubscriptionIncluded(
        availableTime.endTime,
        user?.program,
      ),
    };
  };

  return (
    <div className="side-info-card">
      <div className="flex gap-2 items-center">
        <div className="shrink-0">
          <img src={icons.date} alt="" />
        </div>
        <div className="uppercase">
          {availableModules.length > 0 || loading
            ? 'Available times'
            : 'No upcoming classes'}
        </div>
      </div>
      {loading && (
        <ContentLoader className="max-h-12">
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="48" />
        </ContentLoader>
      )}
      {availableModules.length > 0 && (
        <>
          <div className="flex gap-4 overflow-x-auto pb-1">
            {availableModules.map((module, index) => {
              const title = module.name === 'Cohort' ? null : module.name;
              return (
                <div
                  key={module.id}
                  className={`px-2 py-1 cursor-pointer transition-all whitespace-nowrap ${
                    activeTabIndex === index
                      ? 'text-blue-primary shadow-blue-underline'
                      : ''
                  }`}
                  onClick={() => setActiveTabIndex(index)}
                >
                  {title ?? 'Available Dates'}
                </div>
              );
            })}
          </div>
          <div className="flex flex-col gap-4">
            {!!availableModules[activeTabIndex].availableTimes.find(
              (availableTime) => availableTime.isBooked,
            ) && (
              <Tag
                text="You already have an active booking"
                color="yellow"
                className="self-start"
              />
            )}
            {availableModules[activeTabIndex].availableTimes.map(
              (availableTime) => (
                <AvailableTimeItem
                  key={availableTime.id}
                  availableTime={availableTime}
                  onClick={() => handleItemClick(availableTime)}
                />
              ),
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CourseAvailableTimes;
