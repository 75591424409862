import React from 'react';

import { useNavigate } from 'react-router-dom';

import { icons } from '../../../../helpers/icons';
import { ButtonRegular } from '../../buttons/ButtonRegular';
import { NAVIGATE_PATH } from '../../../../helpers/constants';

interface IClassesNotFound {
  title: string;
  description: string;
  illustration: string;
  buttonText: string;
  buttonClassName: string;
}

const ClassesNotFound: React.FC<Partial<IClassesNotFound>> = ({
  title,
  illustration,
  description = 'Browse our library of classes to see what you might be eligible to take within your organization’s plan.',
  buttonText = 'Discover classes',
  buttonClassName = 'button-primary',
}) => {
  const navigate = useNavigate();

  return (
    <div className="max-w-[30rem] flex flex-col gap-6 shrink-0">
      <div className="hidden">
        <img src={illustration ?? icons.cannotFindIllustration} alt="classes" />
      </div>
      {title && (
        <div className="text-2xl font-bold text-core-dark">{title}</div>
      )}
      {description && (
        <div className="text-regular font-medium text-grey-700">
          {description}
        </div>
      )}
      <ButtonRegular
        onClick={() => navigate(NAVIGATE_PATH.publicCourses)}
        className={`${buttonClassName} self-start`}
        text={buttonText}
      />
    </div>
  );
};

export default ClassesNotFound;
