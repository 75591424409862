import React, { useEffect, useRef, useState } from 'react';

import ReactCrop, { centerCrop, Crop, makeAspectCrop } from 'react-image-crop';

import Modal from '../../Modal';
import Overlay from '../../Overlay';
import LoaderButton from '../../buttons/LoaderButton';
import { ImageLoadEvent } from '../../../../types';
import { getCroppedCanvas, showToast } from '../../../../helpers';
import { DEFAULT_ERROR_MESSAGE } from '../../../../helpers/constants';

interface IImageCropModal {
  file: File;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  uploadImage: (image: Blob | null) => Promise<void>;
}

const MIN_DIMENSION = 100;

const ImageCropModal: React.FC<IImageCropModal> = ({
  file,
  isOpen,
  setIsOpen,
  uploadImage,
}) => {
  const imgRef = useRef(null);
  const [crop, setCrop] = useState<Crop>();
  const [dataUrl, setDataUrl] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = () => {
      setIsOpen(true);
      const imageDataUrl = reader.result?.toString() || '';
      const imageElement = new Image();
      imageElement.src = imageDataUrl;
      imageElement.onload = (e: Event) => {
        const target = e.currentTarget as HTMLImageElement;
        if (target.naturalWidth < 100 || target.naturalHeight < 100) {
          showToast('Image must be at least 100 x 100 pixels.', 'error');
          return setDataUrl('');
        }
      };
      setDataUrl(imageDataUrl);
    };
    reader.readAsDataURL(file);
  }, [file]);

  const handleClose = () => setIsOpen(false);

  const handleImageLoad = (e: ImageLoadEvent) => {
    const { width, height } = e.currentTarget;
    const aspectCrop = makeAspectCrop(
      {
        unit: '%',
        width: 75,
      },
      1,
      width,
      height,
    );
    const centeredCrop = centerCrop(aspectCrop, width, height);
    setCrop(centeredCrop);
  };

  const uploadCroppedImage = () => {
    return new Promise<void>((resolve, reject) => {
      const image = imgRef.current as HTMLImageElement | null;
      const canvas = getCroppedCanvas(image, crop);
      if (!canvas) {
        return reject();
      }
      canvas.toBlob(async (blob) => {
        if (!blob) {
          return reject();
        }
        await uploadImage(new File([blob], file.name, { type: file.type }));
        resolve();
      });
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    uploadCroppedImage()
      .catch(() => showToast(DEFAULT_ERROR_MESSAGE, 'error'))
      .finally(() => setLoading(false));
  };

  if (!isOpen || !dataUrl) {
    return null;
  }

  return (
    <Overlay isOpen={isOpen} onClose={handleClose} className="max-sm:w-auto">
      <Modal title="Crop your new picture" onClose={handleClose}>
        <ReactCrop
          className="w-full"
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          aspect={1}
          minWidth={MIN_DIMENSION}
          keepSelection
        >
          <img
            ref={imgRef}
            src={dataUrl}
            alt=""
            onLoad={handleImageLoad}
            className="w-full"
          />
        </ReactCrop>
        <div className="flex justify-end">
          <LoaderButton
            text="Set new picture"
            className="button-primary w-44"
            isLoading={loading}
            onClick={handleSubmit}
          />
        </div>
      </Modal>
    </Overlay>
  );
};

export default ImageCropModal;
