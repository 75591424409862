import { useState } from 'react';

import LogoIcon from '../../../assets/images/Learnit_logo.svg';
import { AuthLayout } from '../../../components/common/AuthLayout';
import { LogoButton } from '../../../components/common/buttons/LogoButton';
import { LoginForm } from '../../../components/forms/LoginForm';
import InvalidEmail from '../../../components/common/AuthLayout/EmailSent';
import CreatePasswordBanner from '../../../components/common/AuthLayout/CreatePasswordBanner';
import { useErrorRequestParam } from '../../../hooks/hooks';

export const Login = () => {
  const [emailSent, setEmailSent] = useState(false);

  useErrorRequestParam();

  return (
    <AuthLayout
      button={<LogoButton logo={LogoIcon} />}
      banner={<CreatePasswordBanner />}
    >
      {emailSent ? (
        <InvalidEmail title="Activation link sent" />
      ) : (
        <LoginForm setEmailSent={setEmailSent} />
      )}
    </AuthLayout>
  );
};
