import React from 'react';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import 'react-image-crop/dist/ReactCrop.css';

import { Routes } from './components/Routes';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { setupStore } from './store/store';

const container = document.getElementById('root')!;
const root = createRoot(container);
const store = setupStore();

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Routes />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
