import React from 'react';

import ClassesNotFound from '../../../dashboard/ClassesNotFound';
import Delimiter from '../../../Delimiter';
import { icons } from '../../../../../helpers/icons';

const CheckoutPlaceholder: React.FC = () => {
  return (
    <div className="flex flex-col flex-grow">
      <div className="checkout-header">Checkout</div>
      <Delimiter className="my-4 bg-grey-200" />
      <ClassesNotFound
        illustration={icons.nothingToDisplayIllustration}
        description="You have nothing in your basket at the moment."
        buttonText="Book your next course"
        buttonClassName="button-outline"
      />
    </div>
  );
};

export default CheckoutPlaceholder;
