import React from 'react';

import { ButtonRegular } from '../ButtonRegular';
import { icons } from '../../../../helpers/icons';

interface ILoaderButton {
  text: string;
  isLoading: boolean;
  className: string;
  onClick?: () => void;
}

const LoaderButton: React.FC<ILoaderButton> = ({
  text,
  isLoading,
  className,
  onClick,
}) => {
  return isLoading ? (
    <ButtonRegular
      className={`flex justify-center pointer-events-none ${className}`}
      icon={
        <img src={icons.loading} className="animate-spin-slow h-5" alt="" />
      }
    />
  ) : (
    <ButtonRegular
      text={text}
      className={className}
      onClick={onClick}
      type="submit"
    />
  );
};

export default LoaderButton;
