import { differenceInDays } from 'date-fns';

import { TCart } from '../types';

export const getAccessToken = () => {
  return localStorage.getItem('token');
};

export const getCartFromStorage = (): TCart | null => {
  try {
    const cartString = localStorage.getItem('cart');
    if (!cartString) {
      return null;
    }
    const parsedCart: TCart = JSON.parse(cartString);
    if (differenceInDays(new Date(), parsedCart.createdAt) > 1) {
      removeCartFromStorage();
      return null;
    }
    return parsedCart;
  } catch (error) {
    console.error('Error parsing cart from localStorage:', error);
    return null;
  }
};

export const saveCart = (cart: TCart) => {
  try {
    localStorage.setItem('cart', JSON.stringify(cart));
  } catch (error) {
    console.error('Error saving cart to localStorage:', error);
  }
};

export const getFromLocalStorage = <T>(key: string): T | null => {
  try {
    const valueString = localStorage.getItem(key);
    if (!valueString) {
      return null;
    }
    return JSON.parse(valueString) as T;
  } catch (error) {
    console.error('Error parsing value from localStorage:', error);
    return null;
  }
};

export const saveToLocalStorage = (key: string, value: unknown) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error('Error saving value to localStorage:', error);
  }
};

export const removeCartFromStorage = () => {
  localStorage.removeItem('cart');
};
