import { AuthLayout } from '../../../components/common/AuthLayout';
import { LogoButton } from '../../../components/common/buttons/LogoButton';
import { ConfirmPasswordForm } from '../../../components/forms/SetPassword';
import LogoIcon from '../../../assets/images/Learnit_logo.svg';

export const SetPassword = () => {
  return (
    <AuthLayout button={<LogoButton logo={LogoIcon} />}>
      <ConfirmPasswordForm />
    </AuthLayout>
  );
};
