import React from 'react';

import { FormikValues } from 'formik';

export const FormInput: React.FC<Partial<FormikValues>> = ({
  label,
  handleChange,
  handleBlur,
  className,
  error,
  touched,
  ...props
}) => {
  const isErr = error && touched;

  return (
    <div className={className}>
      <label className="block capitalize mb-2 text-heading text-base disabled:opacity-5">
        {label}
      </label>
      <div className="relative"></div>
      <input
        autoComplete={'off'}
        type="text"
        onBlur={handleBlur}
        onChange={handleChange}
        className={`${
          isErr
            ? 'border-[#CF6767]'
            : 'focus-visible:border-blue-primary border-grey-200'
        } border outline-0 disabled:border-gray-200
          block w-full pl-4 p-3 rounded-2xl text-core-dark font-medium text-regular 
          disabled:placeholder:text-gray-200`}
        required
        {...props}
      />
      {isErr && (
        <div className="text-[#CF6767] lowercase" id="feedback">
          {error}
        </div>
      )}
    </div>
  );
};
