import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import Delimiter from '../../Delimiter';
import SummaryQuantity from './SummaryQuantity';
import SubscriptionTag from '../SubscriptionTag';
import { ButtonRegular } from '../../buttons/ButtonRegular';
import { useAppSelector } from '../../../../hooks/hooks';
import { icons } from '../../../../helpers/icons';
import { NAVIGATE_PATH as PATH } from '../../../../helpers/constants';
import { TCartItem } from '../../../../types';

interface IOrderSummaryCard {
  buttonHidden?: boolean;
  nextStepDisabled?: boolean;
  checkoutItems?: TCartItem[];
  finalized?: {
    totalPayed: number;
  };
}

const OrderSummaryCard: React.FC<IOrderSummaryCard> = ({
  buttonHidden = false,
  nextStepDisabled = false,
  checkoutItems,
  finalized,
}) => {
  const { user, cart, cartTotal, cartTotalToPay } = useAppSelector(
    (state) => state.userReducer,
  );
  const [open, setOpen] = useState(false);

  const items =
    checkoutItems ??
    (cart.status === 'second_step'
      ? [...cart.processed, ...cart.public]
      : [...cart.public, ...cart.private]);
  const totalToPay = finalized?.totalPayed ?? cartTotalToPay;

  const totalQuantity = items.reduce(
    (acc, { studentIds }) => acc + (studentIds?.length || 1),
    0,
  );
  const paidQuantity = items.reduce(
    (acc, { included, studentIds }) =>
      included ? acc : acc + (studentIds?.length || 1),
    0,
  );

  const renderButton = () => {
    if (buttonHidden) {
      return null;
    }

    if ((!cart.public.length && !cart.private.length) || nextStepDisabled) {
      return (
        <ButtonRegular
          text="Go to checkout"
          className="button-primary self-center"
          disabled
        />
      );
    }

    if (!user) {
      return (
        <ButtonRegular
          type="submit"
          text="Go to checkout"
          className="button-primary self-center"
        />
      );
    }

    return (
      <Link to={PATH.firstCheckoutStep} className="flex justify-center">
        <ButtonRegular text="Go to checkout" className="button-primary" />
      </Link>
    );
  };

  return (
    <div
      className={`side-info-card max-xl:mobile-order-card xl:sticky xl:top-16
      max-xl:guest:mobile-order-card max-xl:guest:-bottom-4`}
    >
      <div
        className="pt-6 xl:hidden max-xl:guest:flex xl:guest:hidden flex flex-col gap-2 justify-center cursor-pointer touch-pan-x"
        onClick={() => setOpen(!open)}
        onTouchStart={() => setOpen(!open)}
      >
        <img className="self-center" src={icons.drag} alt="" />
        <div className="text-regular text-blue-primary font-bold text-center uppercase">
          Order summary & Check out
        </div>
      </div>
      <div
        className={`flex flex-col gap-4 text-core-dark overflow-hidden
           transition-max-height ease-in-out duration-700 ${
             open
               ? 'max-xl:max-h-[50vh] max-xl:guest:max-h-[50vh]'
               : 'max-xl:max-h-0 max-xl:guest:max-h-0'
           }`}
      >
        <div className="max-xl:hidden text-regular text-grey-700 font-bold uppercase">
          Order summary
        </div>
        <div className="p-2 flex flex-col gap-2 text-regular bg-white rounded">
          {user?.program && (
            <div className="flex justify-between items-center">
              <div>Default voucher connected</div>
              <SubscriptionTag />
            </div>
          )}
          {user && (
            <div className="flex gap-4 justify-between items-center">
              <div className="whitespace-nowrap">Org name</div>
              <div className="text-right font-bold">{user.company.name}</div>
            </div>
          )}
          {user?.program && (
            <SummaryQuantity
              label={`${user.program.type} classes`}
              quantity={totalQuantity - paidQuantity}
              booked={!!finalized || cart.status === 'second_step'}
            />
          )}
          <SummaryQuantity
            label="Paid classes"
            quantity={paidQuantity}
            booked={!!finalized}
          />
        </div>
        {!finalized && (
          <div className="px-2 flex justify-between text-2xl font-bold">
            <div>Total</div>
            <div>${cartTotal.toFixed(2)}</div>
          </div>
        )}
        <Delimiter className="!bg-core-dark" />
        <div className="px-2 flex justify-between text-2xl font-bold">
          <div>{finalized ? 'Paid' : 'To pay'}</div>
          <div className="text-blue-primary text-2xl">
            ${totalToPay.toFixed(2)}
          </div>
        </div>
        {renderButton()}
      </div>
    </div>
  );
};

export default OrderSummaryCard;
