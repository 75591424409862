import React from 'react';

import { TNamedItem } from '../../../../../types';

interface ICourseFilterList {
  filters: TNamedItem[];
  active: TNamedItem | null;
  setActive: (filter: TNamedItem | null) => void;
}

const CourseFilterList: React.FC<ICourseFilterList> = ({
  filters,
  active,
  setActive,
}) => {
  return (
    <div className="flex flex-col gap-2 text-core-dark text-regular font-medium max-xl:max-h-[70vh] max-xl:overflow-y-auto">
      {filters.map((filter) => (
        <div
          key={filter.id}
          onClick={() => setActive(active?.id !== filter.id ? filter : null)}
          className={`${
            active?.id === filter.id
              ? 'bg-blue-100 text-blue-primary hover:bg-blue-100'
              : ''
          } px-2 py-1 rounded cursor-pointer hover:bg-gray-200`}
        >
          {filter.name}
        </div>
      ))}
    </div>
  );
};

export default CourseFilterList;
