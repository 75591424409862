import React from 'react';

import Dropdown from '../../Dropdown';
import { icons } from '../../../../helpers/icons';
import {
  useAppDispatch,
  useAppSelector,
  useDropdown,
} from '../../../../hooks/hooks';
import { setIsCartOpen } from '../../../../store/reducers/userSlice';
import CartTotal from './CartTotal';
import CartItems from './CartItems';
import CartDropdownToggle from './CartDropdownToggle';

const CartDropdown: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isCartOpen, cart } = useAppSelector((state) => state.userReducer);
  const setIsOpen = (isOpen: boolean) => dispatch(setIsCartOpen(isOpen));
  const { handleDropdownToggle, dropdownRef } = useDropdown(
    isCartOpen,
    setIsOpen,
  );

  return (
    <Dropdown
      isOpen={isCartOpen}
      handleDropdownToggle={handleDropdownToggle}
      dropdownRef={dropdownRef}
      className="bg-grey-50 rounded-2xl max-lg:-end-12 max-lg:guest:end-0"
      maxHeight="70vh"
      toggle={<CartDropdownToggle />}
    >
      <div className="w-80 sm:w-96 px-4 py-6 flex flex-col gap-3 text-regular text-core-dark font-medium">
        <div className="flex gap-2 items-center">
          <div className="shrink-0">
            <img src={icons.date} alt="" />
          </div>
          <div className="uppercase whitespace-nowrap">
            {cart.public.length + cart.private.length > 0
              ? 'YOUR SELECTED CLASSES'
              : 'NO SELECTED CLASSES'}
          </div>
        </div>
        <CartItems />
        <CartTotal />
      </div>
    </Dropdown>
  );
};

export default CartDropdown;
