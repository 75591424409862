import React from 'react';

interface IActionLink {
  text: string;
  prefix: string;
  onClick: () => void;
}

const ActionLink: React.FC<IActionLink> = ({ text, prefix, onClick }) => {
  return (
    <div
      className="flex items-center text-blue-primary gap-1 cursor-pointer"
      onClick={onClick}
    >
      <div className="font-semibold">{prefix}</div>
      <div className="underline-link">{text}</div>
    </div>
  );
};

export default ActionLink;
