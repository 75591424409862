import React from 'react';
import { NavLink } from 'react-router-dom';

interface INavTab {
  path: string;
  label: string;
}

const NavItem: React.FC<INavTab> = ({ path, label }) => {
  return (
    <NavLink
      className="mb-1 flex rounded hover:bg-grey-100 text-core-dark [&.active]:text-blue-primary [&.active]:bg-blue-100"
      to={path}
    >
      <div className="p-1 text-regular font-medium">{label}</div>
    </NavLink>
  );
};

export default NavItem;
