import React, { ReactNode } from 'react';

interface IOverlay {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
}

const Overlay: React.FC<IOverlay> = ({
  isOpen,
  onClose,
  children,
  className,
}) => {
  return isOpen ? (
    <div>
      <div
        className="w-screen h-screen fixed z-[998] inset-0 bg-core-dark bg-opacity-80 cursor-pointer"
        onClick={onClose}
      />
      <div
        className={`m-auto px-2 w-fit h-fit fixed z-[999] inset-0 ${className}`}
      >
        <div>{children}</div>
      </div>
    </div>
  ) : null;
};

export default Overlay;
