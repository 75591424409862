import React from 'react';

import { Link } from 'react-router-dom';

import { icons } from '../../../../helpers/icons';
import { NAVIGATE_PATH } from '../../../../helpers/constants';

const CreatePasswordBanner: React.FC = () => {
  return (
    <div className="banner-container">
      <div className="flex items-center gap-2">
        <div className="shrink-0">
          <img src={icons.displayIllustration} alt="" />
        </div>
        <div className="title-calistoga">Need a new password?</div>
      </div>
      <div>
        If you’re an existing Learnit customer and need to create a new password
        for your account.
        <br />
        <Link
          className="text-blue-primary font-bold"
          to={NAVIGATE_PATH.createPassword}
        >
          Click here
        </Link>
      </div>
    </div>
  );
};

export default CreatePasswordBanner;
