import React, { useEffect, useState } from 'react';

import ClassesNotFound from '../../../components/common/dashboard/ClassesNotFound';
import { TClass } from '../../../types';
import Delimiter from '../../../components/common/Delimiter';
import ClassRowSkeleton from '../../../components/common/dashboard/ClassRowSkeleton';
import ClassesHeader from '../../../components/common/dashboard/ClassesHeader';
import NavHeader from '../../../components/common/NavHeader';
import ClassRow from '../../../components/common/dashboard/ClassRow';
import ClassLinkAddress from '../../../components/common/dashboard/ClassLinkAddress';
import { upcomingClasses } from '../../../requests';
import { icons } from '../../../helpers/icons';
import SubNavigation from '../../../components/common/SubNavigation';
import { NAVIGATION_INFO } from '../../../helpers/constants';

const UpcomingClasses = () => {
  const [classes, setClasses] = useState<TClass[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    upcomingClasses()
      .then((classes) => {
        setClasses(classes);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <>
      <NavHeader label="Your upcoming classes" />
      <SubNavigation items={NAVIGATION_INFO.dashboard.items} />
      <div className="flex flex-col pt-4">
        {classes.length === 0 && !isLoading ? (
          <div className="px-4 lg:px-8">
            <ClassesNotFound
              illustration={icons.classesIllustration}
              title="You have no upcoming classes booked"
            />
          </div>
        ) : (
          <>
            <ClassesHeader lastRowHeader="Link/Address" />
            <Delimiter />
            {isLoading && <ClassRowSkeleton />}
            {classes.map((classInfo) => (
              <ClassRow
                key={classInfo.id}
                type="upcoming"
                classInfo={classInfo}
              >
                <ClassLinkAddress classInfo={classInfo} />
              </ClassRow>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default UpcomingClasses;
