import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import CompanyBanner from '../../../components/common/AuthLayout/CompanyBanner';
import EmailSent from '../../../components/common/AuthLayout/EmailSent';
import GetInvitationBanner from '../../../components/common/AuthLayout/GetInvitationBanner';
import LogoIcon from '../../../assets/images/Learnit_logo.svg';
import { AuthLayout } from '../../../components/common/AuthLayout';
import { LogoButton } from '../../../components/common/buttons/LogoButton';
import { SignUpForm } from '../../../components/forms/SignUpForm';
import { getInviteDetails } from '../../../requests';
import { ICompany } from '../../../models/ICompany';
import { useErrorRequestParam } from '../../../hooks/hooks';

export const SignUp = () => {
  const { inviteToken } = useParams();
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState<ICompany | null>(null);

  useErrorRequestParam();

  useEffect(() => {
    getInviteDetails(inviteToken)
      .then((response) => {
        setCompany(response.company);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <AuthLayout
      button={<LogoButton logo={LogoIcon} />}
      banner={
        company ? (
          <CompanyBanner company={company} />
        ) : (
          !loading && <GetInvitationBanner isValid={!inviteToken} />
        )
      }
    >
      {emailSent ? (
        <EmailSent title="Confirmation link sent" />
      ) : (
        <SignUpForm setEmailSent={setEmailSent} disabled={!company} />
      )}
    </AuthLayout>
  );
};
