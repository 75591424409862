import React, { useEffect } from 'react';

import { FieldArray, FormikProps, useFormikContext } from 'formik';
import { useDebounce } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid';

import Delimiter from '../../common/Delimiter';
import { ButtonRegular } from '../../common/buttons/ButtonRegular';
import { FormInput } from '../../common/forms/FormsInput';
import { TStudent } from '../../../types';
import { icons } from '../../../helpers/icons';
import { useAppDispatch } from '../../../hooks/hooks';
import { saveToLocalStorage } from '../../../helpers/localStorage';
import { removeStudentAssignment } from '../../../store/reducers/userSlice';

interface ICheckoutStudentsForm {}

const CheckoutStudentArray: React.FC<ICheckoutStudentsForm> = () => {
  const dispatch = useAppDispatch();
  const { values, handleChange, handleBlur, validateForm, touched } =
    useFormikContext<{
      students: TStudent[];
    }>();

  const [debouncedValues] = useDebounce(values, 1000);

  useEffect(() => {
    saveToLocalStorage('checkoutStudents', debouncedValues.students);
  }, [debouncedValues]);

  const getError = (form: FormikProps<any>, name: string) => {
    const fieldMeta = form.getFieldMeta(name);
    if (fieldMeta.touched && fieldMeta.error) {
      return fieldMeta.error;
    }
  };

  return (
    <FieldArray name="students">
      {({ push, remove, form }) => (
        <div className="flex flex-col gap-1">
          {values.students.map((student, index) => {
            const handleRemove = () => {
              if (values.students.length > 1) {
                dispatch(removeStudentAssignment(student.id));
                remove(index);
              }
            };
            return (
              <div
                className="relative max-sm:flex-col flex gap-2 sm:gap-4 group/student"
                key={index}
              >
                <div className="hidden mt-2 max-sm:flex gap-2 items-center">
                  <span>Student {index + 1}</span>
                  <Delimiter className="grow bg-grey-200" />
                  {index > 0 && (
                    <img
                      className="w-6 cursor-pointer"
                      src={icons.trash}
                      alt=""
                      onClick={handleRemove}
                    />
                  )}
                </div>
                <FormInput
                  className="grow"
                  name={`students[${index}].firstName`}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  placeholder="First Name"
                  value={values.students[index].firstName}
                  touched={touched}
                  error={getError(form, `students[${index}].firstName`)}
                  max={128}
                />
                <FormInput
                  className="grow"
                  name={`students[${index}].lastName`}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  placeholder="Last Name"
                  value={values.students[index].lastName}
                  touched={touched}
                  error={getError(form, `students[${index}].lastName`)}
                  max={128}
                />
                <FormInput
                  className="grow"
                  name={`students[${index}].email`}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  placeholder="Email"
                  value={values.students[index].email}
                  type="email"
                  touched={touched}
                  error={getError(form, `students[${index}].email`)}
                  max={128}
                />
                {index > 0 && (
                  <img
                    className="hidden sm:block absolute w-6 -end-8 top-5 cursor-pointer opacity-0 group-hover/student:opacity-100 transition-opacity  "
                    src={icons.trash}
                    alt=""
                    onClick={handleRemove}
                  />
                )}
              </div>
            );
          })}
          <ButtonRegular
            type="submit"
            text="+ Add one more"
            className="mt-2 button-outline self-start"
            onClick={() => {
              validateForm(values).then((formikError) => {
                if (!formikError.students) {
                  push({
                    id: uuidv4(),
                    firstName: '',
                    lastName: '',
                    email: '',
                  });
                }
              });
            }}
          />
        </div>
      )}
    </FieldArray>
  );
};
export default CheckoutStudentArray;
