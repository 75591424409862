import React from 'react';
import { TCartItem } from '../../../../../../types';

interface IQuantity {
  item: TCartItem;
}

const Quantity: React.FC<IQuantity> = ({ item }) => {
  return item.studentIds?.length || 1;
};

export default Quantity;
