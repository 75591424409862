import React from 'react';

import { ReactComponent as Dashboard } from '../../../../../../../assets/images/dashboard.svg';
import { ReactComponent as Info } from '../../../../../../../assets/images/information.svg';
import { ReactComponent as Library } from '../../../../../../../assets/images/library.svg';
import { ReactComponent as Members } from '../../../../../../../assets/images/members.svg';
import { ReactComponent as Reports } from '../../../../../../../assets/images/reports.svg';
import { ReactComponent as Settings } from '../../../../../../../assets/images/settings.svg';
import { NAVIGATION_TABS as TABS } from '../../../../../../../helpers/constants';

interface INavIcon {
  tabKey: string;
  label?: string;
  active: boolean;
  handleClick?: () => void;
  comingSoon?: boolean;
  hidden?: boolean;
}

const NavIcon: React.FC<INavIcon> = ({
  active,
  handleClick,
  tabKey,
  label,
  comingSoon = false,
  hidden = false,
}) => {
  if (hidden) {
    return null;
  }

  const iconColor = () => {
    return active ? '#223BF4' : '#4A4A68';
  };

  const renderIcon = () => {
    switch (tabKey) {
      case TABS.dashboard:
        return <Dashboard fill={iconColor()} />;
      case TABS.library:
        return <Library fill={iconColor()} />;
      case TABS.reports:
        return <Reports fill={iconColor()} />;
      case TABS.manage:
        return <Members fill={iconColor()} />;
      case TABS.settings:
        return <Settings fill={iconColor()} />;
      case TABS.info:
        return <Info fill={iconColor()} />;
    }
  };
  return (
    <>
      <div
        onClick={comingSoon ? undefined : handleClick}
        className={`flex flex-col items-center rounded-l-lg
          ${!comingSoon ? 'cursor-pointer' : ''} 
          ${active ? 'lg:bg-grey-50 text-blue-primary' : ''}`}
      >
        <div className="py-4 xm:px-4">{renderIcon()}</div>
        <div className={`nav-icon-label ${comingSoon && 'lg:block'}`}>
          {comingSoon ? 'Coming soon' : label || tabKey}
        </div>
      </div>
    </>
  );
};

export default NavIcon;
