import companyLogo from '../assets/images/company-logo.svg';
import arrowDown from '../assets/images/arrowdown.svg';
import arrowLeft from '../assets/images/arrow-left.svg';
import bell from '../assets/images/notification-bell.svg';
import search from '../assets/images/search.svg';
import userIcon from '../assets/images/Ellipse 1.svg';
import logout from '../assets/images/logout.svg';
import copy from '../assets/images/copy.svg';
import location from '../assets/images/location.svg';
import eye from '../assets/images/eye.svg';
import tickCircle from '../assets/images/tick-circle.svg';
import menu from '../assets/images/menu.svg';
import globe from '../assets/images/globe.svg';
import smartphone from '../assets/images/smartphone.svg';
import clock from '../assets/images/clock.svg';
import online from '../assets/images/online.svg';
import close from '../assets/images/close.svg';
import lightBubble from '../assets/images/light-bubble.svg';
import date from '../assets/images/date.svg';
import teamPass from '../assets/images/team-pass.svg';
import teamPassPlus from '../assets/images/team-pass-plus.svg';
import teamPassExpired from '../assets/images/team-pass-expired.svg';
import drag from '../assets/images/drag.svg';
import loading from '../assets/images/loading.svg';
import trash from '../assets/images/trash.svg';
import options from '../assets/images/options.svg';
import book from '../assets/images/book.svg';
import avatarPlaceholder from '../assets/images/avatar-placeholder.svg';
import companyPlaceholder from '../assets/images/company-placeholder.svg';
import coursePlaceholder from '../assets/images/course-placeholder.svg';
import classesIllustration from '../assets/images/classes-illustration.svg';
import pencilIllustration from '../assets/images/pencil-illustration.svg';
import somethingWrongIllustration from '../assets/images/something-wrong-illustration.svg';
import displayIllustration from '../assets/images/display-illustration.svg';
import flyingIllustration from '../assets/images/flying-illustration.svg';
import sittingIllustration from '../assets/images/sitting-illustration.svg';
import cannotFindIllustration from '../assets/images/cannot-find-illustration.svg';
import spannerIllustration from '../assets/images/spanner-illustration.svg';
import nothingToDisplayIllustration from '../assets/images/nothing-to-display-illustration.svg';
import stairsIllustration from '../assets/images/stairs-illustration.svg';
import holdingBooksIllustration from '../assets/images/holding-books-illustration.svg';

export const icons = {
  companyLogo,
  arrowDown,
  arrowLeft,
  bell,
  search,
  userIcon,
  logout,
  copy,
  location,
  eye,
  tickCircle,
  menu,
  globe,
  smartphone,
  clock,
  online,
  close,
  lightBubble,
  date,
  teamPass,
  teamPassPlus,
  teamPassExpired,
  drag,
  loading,
  trash,
  options,
  book,
  avatarPlaceholder,
  companyPlaceholder,
  coursePlaceholder,
  classesIllustration,
  pencilIllustration,
  somethingWrongIllustration,
  displayIllustration,
  flyingIllustration,
  sittingIllustration,
  cannotFindIllustration,
  spannerIllustration,
  nothingToDisplayIllustration,
  stairsIllustration,
  holdingBooksIllustration,
};
