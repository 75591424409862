import React from 'react';

interface IPrice {
  price: number;
  quantity: number;
  isPrivate: boolean;
  discounted: boolean;
}

const Price: React.FC<Partial<IPrice>> = ({
  price,
  quantity = 1,
  isPrivate,
  discounted,
}) => {
  const renderPrice = () => {
    if (isPrivate) {
      return 'Already paid';
    }

    if (!price) {
      return '—';
    }

    const totalPrice = (price * quantity).toFixed(2);

    if (discounted) {
      return (
        <>
          <div className="text-[11px] line-through leading-none">
            ${totalPrice}
          </div>
          <div>$0.00</div>
        </>
      );
    }

    return <>${totalPrice}</>;
  };
  return <div className="price">{renderPrice()}</div>;
};

export default Price;
