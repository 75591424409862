import React, { useState } from 'react';

import ImageControl from '../../../components/common/settings/ImageControl';
import NavHeader from '../../../components/common/NavHeader';
import { useAppSelector } from '../../../hooks/hooks';
import { showToast } from '../../../helpers';
import { login } from '../../../requests';

const ProfileSettings = () => {
  const { user } = useAppSelector((state) => state.userReducer);
  const [loadingPasswordRequest, setLoadingPasswordRequest] = useState(false);
  const fullName = `${user?.firstName} ${user?.lastName}`;

  const handlePasswordChange = async () => {
    if (loadingPasswordRequest) {
      return;
    }
    setLoadingPasswordRequest(true);
    const response = await login(user!.email);
    if (response.email) {
      showToast(
        `Reset link sent to ${response.email}. If no action is taken the password will remain the same`,
        'success',
        { duration: 8000 },
      );
    }
    setLoadingPasswordRequest(false);
  };

  // TODO: display phone and number of attended classes from SF
  return (
    <>
      <NavHeader label="Profile Settings" />
      <div className="main-container w-full pt-4 gap-2 max-w-[54rem] ">
        <div className="pb-3 text-regular text-core-dark font-medium">
          Manage your account settings below
        </div>
        <div className="settings-header">{fullName}</div>
        <table className="settings-table">
          <tbody>
            <tr>
              <td>Profile picture</td>
              <td>
                <ImageControl src={user?.photo} type={'user'} />
              </td>
            </tr>
            <tr>
              <td>Name</td>
              <td className="w-full text-black bg-grey-50 rounded">
                {fullName}
              </td>
            </tr>
            <tr>
              <td>Contact Email</td>
              <td className="text-core-dark">{user?.email}</td>
            </tr>
            {!user?.individual && (
              <tr>
                <td>Company</td>
                <td className="text-core-dark">{user?.company.name}</td>
              </tr>
            )}
            <tr className="border-t border-grey-100"></tr>
            <tr>
              <td>Password</td>
              <td className="underline-link" onClick={handlePasswordChange}>
                Request change password link
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ProfileSettings;
