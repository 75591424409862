import React from 'react';

import CheckoutItemPreview from '../CheckoutOverview/CheckoutSummaryTable/CheckoutItem/CheckoutItemPreview';
import Delimiter from '../../Delimiter';
import { TCartItem } from '../../../../types';

interface IBookingSummary {
  items: TCartItem[];
}

const BookingSummary: React.FC<IBookingSummary> = ({ items }) => {
  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex justify-between text-xs text-black font-medium">
        <div className="md:min-w-[14rem]">Course</div>
        <div className="min-w-[2rem] md:min-w-[8rem] text-center">Seats</div>
      </div>
      <Delimiter className="bg-grey-200" />
      <div className="flex gap-4 flex-col">
        {items.map((item) => (
          <React.Fragment key={item.id}>
            <div className="flex flex-grow justify-between">
              <CheckoutItemPreview item={item} />
              <div className="min-w-[2rem] md:min-w-[8rem] flex justify-center items-center">
                <span>{item.studentIds?.length || 1}</span>
              </div>
            </div>
            <Delimiter className="bg-grey-200" />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default BookingSummary;
