import React, { useState } from 'react';

import Overlay from '../../../Overlay';
import { icons } from '../../../../../helpers/icons';
import { useAppSelector, useOverflowHidden } from '../../../../../hooks/hooks';
import CourseFilterModal from '../CourseFilterModal';

const CourseFilterButton: React.FC = () => {
  const { activeFilters } = useAppSelector((state) => state.libraryReducer);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useOverflowHidden(isModalOpen);

  return (
    <>
      <Overlay
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="max-sm:w-auto"
      >
        <CourseFilterModal onClose={() => setIsModalOpen(false)} />
      </Overlay>
      <div
        className="px-2 py-1 flex gap-2 items-center border border-core-dark rounded bg-white cursor-pointer"
        onClick={() => setIsModalOpen(true)}
      >
        <div className="text-regular text-core-dark font-medium">
          Filter by
          {activeFilters.secondaryFilter &&
            `: ${activeFilters.secondaryFilter.name}`}
        </div>
        <div className="shrink-0">
          <img src={icons.arrowDown} alt="" />
        </div>
      </div>
    </>
  );
};

export default CourseFilterButton;
