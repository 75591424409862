import React from 'react';

import ReadingMaterialLink from '../../buttons/ReadingMaterialLink';
import { formatToSimpleDateTime } from '../../../../helpers';

interface IClassDetails {
  startTime: string;
  name: string;
  description?: string;
  readingMaterialLink?: string;
}

const ClassDetails: React.FC<IClassDetails> = ({
  startTime,
  name,
  description,
  readingMaterialLink,
}) => {
  return (
    <div className="flex flex-col">
      <div className="text-blue-primary uppercase font-medium">
        {formatToSimpleDateTime(startTime)}
      </div>
      <div className="xl:w-[26rem] 3xl:w-[30rem] text-heading-dark whitespace-normal">
        {name}
      </div>
      <div className="xl:w-[26rem] 3xl:w-[30rem] text-sm md:text-regular text-grey-700 font-medium">
        {description}
      </div>
      {readingMaterialLink && (
        <div className="flex justify-end">
          <ReadingMaterialLink
            text="Class reading material"
            link={readingMaterialLink}
          />
        </div>
      )}
    </div>
  );
};

export default ClassDetails;
