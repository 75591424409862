import React, { useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import LoadingImage from '../../LoadingImage';
import Delimiter from '../../Delimiter';
import Dropdown from '../../Dropdown';
import UserDropdownToggle from './UserDropdownToggle';
import { logout } from '../../../../requests';
import { NAVIGATE_PATH } from '../../../../helpers/constants';
import {
  useAppDispatch,
  useAppSelector,
  useDropdown,
} from '../../../../hooks/hooks';
import { companyLogo, userAvatar } from '../../../../helpers';
import { clearCart } from '../../../../store/reducers/userSlice';

const UserDropdown: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, isManager } = useAppSelector((state) => state.userReducer);
  const [isOpen, setIsOpen] = useState(false);
  const { handleDropdownToggle, dropdownRef } = useDropdown(isOpen, setIsOpen);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(clearCart());
    logout().then(() => navigate(NAVIGATE_PATH.login, { replace: true }));
  };

  return (
    <Dropdown
      isOpen={isOpen}
      handleDropdownToggle={handleDropdownToggle}
      dropdownRef={dropdownRef}
      toggle={<UserDropdownToggle />}
    >
      <div className="w-64 p-2 flex flex-col gap-1 text-regular text-core-dark font-medium">
        <div className="dropdown-item p-0 gap-4 pointer-events-none">
          <LoadingImage src={userAvatar(user)} className="avatar" />
          <div className="grow font-bold">
            {user?.firstName} {user?.lastName}
          </div>
        </div>
        <Link className="dropdown-item" to={NAVIGATE_PATH.profileSettings}>
          <div>Profile settings</div>
        </Link>
        <Delimiter />
        {!user?.individual && (
          <>
            <div className="dropdown-item p-0 gap-4 pointer-events-none">
              <LoadingImage
                className="w-8 h-8 rounded-lg"
                src={companyLogo(user?.company)}
              />
              <div className="grow font-bold">{user?.company.name}</div>
            </div>
            <Link
              className="dropdown-item"
              to={
                isManager
                  ? NAVIGATE_PATH.companySettings
                  : NAVIGATE_PATH.companyInfo
              }
            >
              <div>Company {isManager ? 'settings' : 'info'}</div>
            </Link>
          </>
        )}
        <div className="dropdown-item" onClick={handleLogout}>
          <div>Log out</div>
        </div>
      </div>
    </Dropdown>
  );
};

export default UserDropdown;
