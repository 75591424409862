import React, { useEffect } from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { fetchUser } from '../../../store/reducers/actionCreators';
import { NAVIGATE_PATH } from '../../../helpers/constants';

const AnonymousRoute: React.FC = () => {
  const { user, isLoading } = useAppSelector((state) => state.userReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUser());
  }, []);

  if (user || isLoading) {
    return isLoading ? null : <Navigate to={NAVIGATE_PATH.upcomingClasses} />;
  }
  return <Outlet />;
};

export default AnonymousRoute;
