import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';

import {
  getSignUpValidate,
  signUpInitialValue,
} from '../../../helpers/validateLoginData';
import { ButtonRegular } from '../../common/buttons/ButtonRegular';
import { FormInput } from '../../common/forms/FormsInput';
import { icons } from '../../../helpers/icons';
import AgreementDescription from '../../common/AuthLayout/AgreementDescription';
import { NAVIGATE_PATH } from '../../../helpers/constants';
import { TSignUpValues } from '../../../types';
import { signUp } from '../../../requests';

interface ILoginForm {
  setEmailSent: (emailSent: boolean) => void;
  disabled: boolean;
}

export const SignUpForm: React.FC<ILoginForm> = ({
  setEmailSent,
  disabled,
}) => {
  const [emailTaken, setIsEmailTaken] = useState(false);
  const handleSubmit = async (values: TSignUpValues) => {
    try {
      const response = await signUp(values);
      if (response.email) {
        setEmailSent(true);
      }
    } catch (e: any) {
      if (e.response?.status == 400) {
        setIsEmailTaken(true);
      } else {
        console.error(e);
      }
    }
  };

  return (
    <div className="form-container">
      <img
        className="form-illustration-left"
        src={icons.flyingIllustration}
        alt=""
      />
      <Formik
        validate={getSignUpValidate}
        initialValues={signUpInitialValue}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          handleSubmit,
          handleBlur,
          handleChange,
          values,
          touched,
          errors,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="lg:max-w-[440px] m-auto 2xl:m-0 grid justify-items-center">
              <div className="form-title">Sign up</div>
              {emailTaken && (
                <div className="message-container">
                  <div className="title-calistoga">
                    An account is already registered with this email address
                  </div>
                  <div className="text-regular">
                    Please try using a different email, or if this is your
                    account, consider
                    <Link className="action-link" to={NAVIGATE_PATH.login}>
                      <span> logging in</span>
                    </Link>
                  </div>
                </div>
              )}
              <div className="w-full flex justify-between mb-6">
                <FormInput
                  handleChange={handleChange}
                  className="w-full mr-5"
                  name={'firstName'}
                  type={'string'}
                  handleBlur={handleBlur}
                  value={values.firstName}
                  placeholder="First name"
                  touched={touched['firstName']}
                  error={errors['firstName']}
                  disabled={disabled}
                />
                <FormInput
                  handleChange={handleChange}
                  className="w-full"
                  name={'lastName'}
                  type={'string'}
                  handleBlur={handleBlur}
                  value={values.lastName}
                  placeholder="Last name"
                  touched={touched['lastName']}
                  error={errors['lastName']}
                  disabled={disabled}
                />
              </div>
              <FormInput
                handleChange={handleChange}
                className="w-full mb-6"
                name={'email'}
                type={'email'}
                handleBlur={handleBlur}
                value={values.email}
                placeholder="Email"
                touched={touched['email']}
                error={errors['email']}
                disabled={disabled}
              />
              <AgreementDescription action="Create account" />
              <div className="grid w-full mt-4 justify-items-center">
                <ButtonRegular
                  className="button-primary"
                  type={'submit'}
                  text="create account"
                  disabled={isSubmitting || disabled}
                />
              </div>
              <div className="grid w-full mt-4 justify-items-center">
                <span className="font-medium">
                  Already have an account?
                  <Link to={NAVIGATE_PATH.login}>
                    <span className="text-blue-one"> Log in</span>
                  </Link>
                </span>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
