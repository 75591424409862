import React from 'react';

import { Link } from 'react-router-dom';

import { LEARNIT_URL } from '../../../../helpers/constants';

interface IAgreementDescription {
  action: string;
}

const AgreementDescription: React.FC<IAgreementDescription> = ({ action }) => {
  return (
    <div className="help-text text-xs">
      By clicking "{action}" below, you acknowledge that you have read and
      understood, and agree to Learnit’s{' '}
      <Link to={`${LEARNIT_URL}/terms-cancellation-policy`} target="_blank">
        <span className="underline">Terms & Conditions</span>
      </Link>
      <span> and </span>
      <Link to={`${LEARNIT_URL}/privacy-policy`} target="_blank">
        <span className="underline">Privacy Policy</span>
      </Link>
    </div>
  );
};

export default AgreementDescription;
