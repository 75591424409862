import React from 'react';

import Lottie from 'react-lottie';

import { LogoButton } from '../buttons/LogoButton';
import LogoIcon from '../../../assets/images/Learnit_logo.svg';
import loadingSpinnerAnimation from '../../../assets/animations/loading-spinner.json';

interface ILoadingOverlay {
  text: string;
}

const animationOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingSpinnerAnimation,
};

const LoadingOverlay: React.FC<ILoadingOverlay> = ({ text }) => {
  return (
    <div className="absolute top-0 left-0 w-screen h-screen flex flex-col bg-white z-[9999]">
      <div className="pt-6 mx-auto auth-container">
        <LogoButton logo={LogoIcon} />
      </div>
      <div className="h-1/2 flex flex-col justify-end items-center">
        <div>
          <Lottie options={animationOptions} height={200} width={400} />
        </div>
        <div className="w-80 text-center text-regular text-grey-700 font-bold">
          {text}
        </div>
      </div>
    </div>
  );
};

export default LoadingOverlay;
