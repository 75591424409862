import { combineReducers, configureStore } from '@reduxjs/toolkit';

import userReducer from './reducers/userSlice';
import libraryReducer from './reducers/librarySlice';
import checkoutReducer from './reducers/checkoutSlice';

const rootReducer = combineReducers({
  userReducer,
  libraryReducer,
  checkoutReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
