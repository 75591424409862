import React from 'react';

import CheckoutItem from './CheckoutItem';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import {
  removePrivateCourse,
  togglePublicCourse,
} from '../../../../../store/reducers/userSlice';
import { TStudent } from '../../../../../types';

interface ICheckoutSummaryTable {
  students?: TStudent[];
}

const CheckoutSummaryTable: React.FC<ICheckoutSummaryTable> = ({
  students,
}) => {
  const dispatch = useAppDispatch();
  const { cart } = useAppSelector((state) => state.userReducer);

  return (
    <div className="max-lg:pb-16 w-full flex flex-col">
      <div className="hidden 2xl:flex justify-between text-xs text-black font-medium">
        <div className="flex flex-grow justify-between">
          <div className="md:min-w-[14rem]">Course</div>
          <div className="md:w-full max-w-[4rem] 3xl:max-w-[8rem] text-center">
            Quantity
          </div>
        </div>
        <div className="min-w-[8rem] pr-8 text-right">Price</div>
      </div>
      <div className="flex flex-col">
        {cart.private.map((item) => (
          <CheckoutItem
            key={item.id}
            item={item}
            onRemove={() => dispatch(removePrivateCourse(item))}
            isPrivate
          />
        ))}
        {cart.public.map((item) => (
          <CheckoutItem
            key={item.id}
            item={item}
            students={students}
            price={item.price}
            onRemove={() => dispatch(togglePublicCourse(item))}
          />
        ))}
      </div>
    </div>
  );
};
export default CheckoutSummaryTable;
