import React from 'react';

import NavItem from '../NavbarLayout/Navbar/NavigationTabs/NavItem';
import Select from '../Select';
import { TNavItem, TOption } from '../../../types';
import Tag from '../Tag';

interface ISubNavigation {
  items: TNavItem[];
  select?: {
    options: TOption[];
    active: any;
    setActive: (value: any) => void;
  };
}

const SubNavigation: React.FC<ISubNavigation> = ({ items, select }) => {
  return (
    <>
      <div className="px-4 py-2 flex lg:hidden justify-between gap-4">
        <div className="flex gap-4">
          {items.map((item) => {
            return (
              <NavItem
                key={item.path}
                path={item.path}
                label={item.shortLabel ?? item.label}
              />
            );
          })}
        </div>
        {select && <Select {...select} />}
      </div>
      {select && (
        <div className="hidden lg:flex pl-8 py-6 gap-6">
          {select.options.map((option, index) => {
            return (
              <Tag
                onClick={() => select.setActive(option.value)}
                key={index}
                text={option.text}
                color={select.active === option.value ? 'primary' : 'base'}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default SubNavigation;
