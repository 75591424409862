import React from 'react';

import Tag from '../../Tag';
import { TColor } from '../../../../types';

interface ICheckoutTag {
  text: string;
  color?: TColor;
  icon?: string;
  iconHidden?: boolean;
  className?: string;
}

const CheckoutTag: React.FC<ICheckoutTag> = ({
  text,
  icon,
  iconHidden = false,
  color = 'primary',
  className = '',
}) => {
  return (
    <Tag
      text={text}
      icon={iconHidden ? undefined : icon}
      color={color}
      className={`font-semibold lato !p-[3px] ${className}`}
    />
  );
};

export default CheckoutTag;
