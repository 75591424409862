import React from 'react';

import CheckoutStudentArray from '../../../forms/CheckoutStudentArray';
import CheckoutSectionHeader from '../CheckoutSectionHeader';
import Delimiter from '../../Delimiter';

const CheckoutAddStudents: React.FC = () => {
  return (
    <section className="grey-container">
      <div>
        <CheckoutSectionHeader
          header="1. Add student/s"
          description="Enter the name and email address of the student/s these classes are for"
          showShoppingButton={false}
        />
        <Delimiter className="mt-2 bg-grey-200 max-sm:hidden" />
      </div>
      <CheckoutStudentArray />
    </section>
  );
};

export default CheckoutAddStudents;
