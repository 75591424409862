import React from 'react';

import { TColor } from '../../../types';
import { resolveTagClassName } from '../../../helpers';

interface IMiniTag {
  text: string;
  color: TColor;
  className?: string;
}

const MiniTag: React.FC<IMiniTag> = ({ text, color, className = '' }) => {
  return (
    <div
      className={`pointer-events-none ${resolveTagClassName(color)} px-1 lato
      rounded font-bold text-xs text-center whitespace-nowrap inline-block ${className}`}
    >
      <div>{text}</div>
    </div>
  );
};

export default MiniTag;
