import React from 'react';

import { TColor } from '../../../types';
import { resolveTagClassName } from '../../../helpers';

interface ITag {
  text: string;
  color: TColor;
  className?: string;
  onClick?: () => void;
  icon?: string;
}

const Tag: React.FC<ITag> = ({
  text,
  color,
  onClick,
  icon,
  className = '',
}) => {
  return (
    <div
      onClick={onClick}
      className={`${onClick ? 'cursor-pointer' : 'pointer-events-none'}
      ${resolveTagClassName(color)} 
      py-1 px-2 flex shrink-0 items-center gap-1 rounded font-medium
      text-regular text-center whitespace-nowrap ${className}`}
    >
      {icon && <img src={icon} alt="" />}
      <div>{text}</div>
    </div>
  );
};

export default Tag;
