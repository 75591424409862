import React, { useEffect, useState } from 'react';

import ContentLoader from 'react-content-loader';

import CourseFilterGroup from '../CourseFilterGroup';
import CourseFilterList from '../CourseFilterList';
import { getCourseFilters } from '../../../../../requests';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import {
  filterCourses,
  setCourseFilters,
} from '../../../../../store/reducers/librarySlice';

const CourseFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const { secondaryFilters, activeFilters } = useAppSelector(
    (state) => state.libraryReducer,
  );
  const [filtersLoading, setFiltersLoading] = useState(true);

  useEffect(() => {
    getCourseFilters()
      .then((filters) => {
        dispatch(setCourseFilters(filters));
      })
      .catch((error) => console.error(error))
      .finally(() => setFiltersLoading(false));
  }, []);

  return (
    <div className="hidden xl:block w-full max-w-[12.25rem] text-regular text-core-dark">
      {filtersLoading ? (
        <div>
          <div className="font-bold mb-2">Filter Categories</div>
          <ContentLoader className="max-w-[12.25rem]">
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="32" />
            <rect x="0" y="40" rx="5" ry="5" width="100%" height="32" />
          </ContentLoader>
        </div>
      ) : (
        <CourseFilterGroup title="Filter Categories">
          <CourseFilterList
            filters={secondaryFilters}
            active={activeFilters.secondaryFilter}
            setActive={(secondaryFilter) =>
              dispatch(filterCourses({ secondaryFilter }))
            }
          />
        </CourseFilterGroup>
      )}
    </div>
  );
};

export default CourseFilters;
