import React from 'react';

import { Link } from 'react-router-dom';

import { icons } from '../../../../helpers/icons';
import { ButtonRegular } from '../../buttons/ButtonRegular';
import { NAVIGATE_PATH } from '../../../../helpers/constants';
import ContactUs from '../ContactUs';

const InvalidEmail: React.FC = () => {
  return (
    <div className="form-container min-w-[22rem] md:min-w-[31rem] items-center">
      <div className="form-title">Invalid email</div>
      <div className="flex justify-center">
        <img src={icons.somethingWrongIllustration} alt="" />
      </div>
      <div className="mx-auto mt-4 sm:px-10 text-center font-bold text-grey-700">
        It appears that this email address doesn't have access to the Learnit
        Portal.
      </div>
      <div className="mx-auto mt-2 sm:px-10 text-center text-regular font-medium text-grey-700">
        Contact your company representative or <ContactUs /> to get access.
      </div>
      <div className="grid w-full mt-4 justify-items-center">
        <Link to={NAVIGATE_PATH.login}>
          <ButtonRegular className="button-primary" text="Back to login page" />
        </Link>
      </div>
    </div>
  );
};

export default InvalidEmail;
