import React from 'react';

import CheckoutSummaryTable from './CheckoutSummaryTable';
import CheckoutSectionHeader from '../CheckoutSectionHeader';
import Delimiter from '../../Delimiter';

const CheckoutOverview: React.FC = () => {
  return (
    <div className="flex flex-col flex-grow">
      <CheckoutSectionHeader header="Checkout" />
      <Delimiter className="mt-4 md:mb-2 bg-grey-200" />
      <CheckoutSummaryTable />
    </div>
  );
};

export default CheckoutOverview;
