import React from 'react';

import { ReactComponent as CartIcon } from '../../../../../assets/images/cart.svg';
import { useAppSelector } from '../../../../../hooks/hooks';

const CartDropdownToggle: React.FC = () => {
  const { cart } = useAppSelector((state) => state.userReducer);

  const cartItemsLength = cart.public.length + cart.private.length;

  return (
    <div className="flex items-center shrink-0 cursor-pointer">
      <CartIcon fill="#4A4A68" />
      {cartItemsLength > 0 && (
        <div className="w-4 h-4 text-xs text-white text-center font-semibold bg-blue-primary rounded-full">
          {cartItemsLength}
        </div>
      )}
    </div>
  );
};

export default CartDropdownToggle;
