import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import CourseCard from '../CourseCard';
import Delimiter from '../../Delimiter';
import { ButtonRegular } from '../../buttons/ButtonRegular';
import { TCourseGroup } from '../../../../types';
import { NAVIGATE_PATH } from '../../../../helpers/constants';

interface ICourseGroup {
  courseGroup: TCourseGroup;
}

const CourseGroup: React.FC<ICourseGroup> = ({ courseGroup }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="flex flex-col">
      <div className="w-full flex items-center gap-10">
        <div className="text-2xl text-black font-bold">
          {courseGroup.groupName}
        </div>
        <Delimiter className="flex flex-grow bg-grey-200" />
      </div>
      <div className="course-group-container">
        {courseGroup.courses
          .slice(0, showMore ? courseGroup.courses.length : 3)
          .map((course) => (
            <Link
              key={course.id}
              className="course-card"
              to={`${NAVIGATE_PATH.publicCourses}/${course.slug}`}
            >
              <CourseCard coursePreview={course} />
            </Link>
          ))}
      </div>
      {!showMore && courseGroup.courses.length > 3 && (
        <ButtonRegular
          className="button-outline self-start mt-10"
          text={`Show ${courseGroup.courses.length - 3} more`}
          onClick={() => setShowMore(true)}
        />
      )}
    </div>
  );
};

export default CourseGroup;
