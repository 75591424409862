import React, { ReactNode } from 'react';

interface IFilterGroup {
  title: string;
  children: ReactNode;
}

const CourseFilterGroup: React.FC<IFilterGroup> = ({ title, children }) => {
  return (
    <div className="p-2 flex flex-col text-regular font-medium gap-2">
      <div className="font-bold">{title}</div>
      {children}
    </div>
  );
};

export default CourseFilterGroup;
