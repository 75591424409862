import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { subDays } from 'date-fns';

import NavHeader from '../../../components/common/NavHeader';
import ClassesNotFound from '../../../components/common/dashboard/ClassesNotFound';
import { TClass } from '../../../types';
import Delimiter from '../../../components/common/Delimiter';
import ClassesHeader from '../../../components/common/dashboard/ClassesHeader';
import ClassRow from '../../../components/common/dashboard/ClassRow';
import EvaluationButton from '../../../components/common/dashboard/EvaluationButton';
import { NAVIGATION_INFO } from '../../../helpers/constants';
import SubNavigation from '../../../components/common/SubNavigation';
import { pastClasses } from '../../../requests';
import ClassRowSkeleton from '../../../components/common/dashboard/ClassRowSkeleton';

const PastClasses = () => {
  const [period, setPeriod] = useState(7);
  const [classes, setClasses] = useState<TClass[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setIsLoading(true);
    setClasses([]);

    const fromDate = period
      ? subDays(new Date(), period).toISOString()
      : undefined;

    pastClasses(fromDate, signal)
      .then((classes) => {
        setClasses(classes);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      });

    return () => {
      controller.abort();
    };
  }, [period]);

  return (
    <>
      <NavHeader label="Your past classes" />
      <SubNavigation
        items={NAVIGATION_INFO.dashboard.items}
        select={{
          options: [
            { text: 'Last week', value: 7 },
            { text: 'Last month', value: 30 },
            { text: 'Last 6 months', value: 180 },
            { text: 'All', value: null },
          ],
          active: period,
          setActive: setPeriod,
        }}
      />
      <div className="flex flex-col">
        {classes.length === 0 && !isLoading ? (
          <div className="px-4 lg:px-8">
            <ClassesNotFound title="You have no past classes attended" />
          </div>
        ) : (
          <>
            <ClassesHeader lastRowHeader="Class evaluation" />
            <Delimiter />
            {isLoading && <ClassRowSkeleton />}
            {classes.map((classInfo) => (
              <ClassRow key={classInfo.id} classInfo={classInfo} type="past">
                {classInfo.evaluationLink && (
                  <EvaluationButton evaluationLink={classInfo.evaluationLink} />
                )}
              </ClassRow>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default PastClasses;
