import React from 'react';

import CheckoutTag from '../CheckoutTag';
import { useAppSelector } from '../../../../hooks/hooks';
import { icons } from '../../../../helpers/icons';
import { ProgramType, TColor } from '../../../../types';
import { getSubscriptionIcon } from '../../../../helpers';

interface ISubscriptionTag {
  iconHidden?: boolean;
  active?: boolean;
}

const SubscriptionTag: React.FC<ISubscriptionTag> = ({
  iconHidden = false,
  active = true,
}) => {
  const { user } = useAppSelector((state) => state.userReducer);

  if (!user?.program) {
    return null;
  }

  const getSubscriptionColor = (): TColor => {
    switch (user.program!.type) {
      case ProgramType.TEAM_PASS:
      case ProgramType.TEAM_PASS_PLUS:
        return 'primary';
      default:
        return 'neutral';
    }
  };

  return (
    <CheckoutTag
      text={user.program.type}
      icon={active ? getSubscriptionIcon(user.program) : icons.teamPassExpired}
      iconHidden={iconHidden}
      color={active ? getSubscriptionColor() : 'danger'}
    />
  );
};

export default SubscriptionTag;
