import React from 'react';

import { Link } from 'react-router-dom';

import { icons } from '../../../../helpers/icons';
import { NAVIGATE_PATH } from '../../../../helpers/constants';

interface IEmailSent {
  title?: string;
}

const EmailSent: React.FC<IEmailSent> = ({ title = 'Reset link sent' }) => {
  return (
    <div className="form-container min-w-[22rem] md:min-w-[31rem] items-center">
      <div className="form-title">{title}</div>
      <div className="flex justify-center">
        <img src={icons.pencilIllustration} alt="" />
      </div>
      <div className="grid w-full justify-items-center text-grey-700">
        Check your mailbox carefully for the link
      </div>
      <div className="grid w-full mt-4 justify-items-center text-grey-700">
        <span className="font-medium">
          Remembered your password?
          <Link to={NAVIGATE_PATH.login} reloadDocument>
            <span className="text-blue-one"> Log in</span>
          </Link>
        </span>
      </div>
    </div>
  );
};

export default EmailSent;
