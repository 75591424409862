import React from 'react';

import CheckoutTag from '../../../../checkout/CheckoutTag';
import Price from '../../../../checkout/Price';
import SubscriptionTag from '../../../../checkout/SubscriptionTag';
import { formatClassDate, formatToDuration } from '../../../../../../helpers';
import { TCartItem } from '../../../../../../types';
import { icons } from '../../../../../../helpers/icons';

interface ICartItem {
  item: TCartItem;
  isPrivate?: boolean;
  price?: number;
  onRemove: () => void;
}

const CartItem: React.FC<ICartItem> = ({
  item,
  isPrivate = false,
  price,
  onRemove,
}) => {
  return (
    <div className="flex flex-col bg-white rounded items-start">
      {isPrivate ? (
        <CheckoutTag text="Private" color="neutral" />
      ) : (
        <SubscriptionTag iconHidden active={item.included} />
      )}
      <div className="px-2 py-1 flex gap-4 self-stretch items-center justify-between">
        <div className="flex flex-col">
          <div className="font-bold">{item.course.name}</div>
          <div>{formatClassDate(item.startTime, item.endTime)}</div>
          <div>{formatToDuration(item.startTime, item.endTime)}</div>
        </div>
        <div className="flex gap-2 items-center shrink-0">
          <Price
            price={price}
            quantity={item.studentIds?.length || 1}
            isPrivate={isPrivate}
            discounted={item.included}
          />
          <div className="py-1 cursor-pointer shrink-0" onClick={onRemove}>
            <img src={icons.close} alt="close" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
