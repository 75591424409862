import React from 'react';

interface IButton {
  text: string;
  onClick: () => void;
  disabled: boolean;
  className: string;
  icon: React.ReactNode;
  type: 'button' | 'submit' | 'reset';
}

export const ButtonRegular: React.FC<
  (Pick<IButton, 'icon'> | Pick<IButton, 'text'>) & Partial<IButton>
> = ({ onClick, className = '', text, disabled, icon, type = 'button' }) => {
  return (
    <button
      data-clipboard-text="Text for copy"
      className={`${className} base-button uppercase`}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {icon}
      {text}
    </button>
  );
};
