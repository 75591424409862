import React from 'react';

import LoadingImage from '../../LoadingImage';
import { icons } from '../../../../helpers/icons';
import { TCoursePreview } from '../../../../types';
import { useAppSelector } from '../../../../hooks/hooks';
import {
  formatToSimpleDateTime,
  getSubscriptionIcon,
  isClassSubscriptionIncluded,
} from '../../../../helpers';
import Tag from '../../Tag';

interface ICourseCard {
  coursePreview: TCoursePreview;
  isBooked?: boolean;
  onClick?: () => void;
}

const CourseCard: React.FC<ICourseCard> = ({
  coursePreview,
  isBooked,
  onClick,
}) => {
  const { user } = useAppSelector((state) => state.userReducer);

  const included = isClassSubscriptionIncluded(
    coursePreview.nearestStartTime,
    user?.program,
  );

  const renderPrice = () => {
    if (!coursePreview.priceRange) {
      return 'No upcoming classes';
    }

    if (included) {
      return (
        <>
          <div className="text-[11px] leading-none">
            <span>From </span>
            <span className="line-through">{coursePreview.priceRange}</span>
          </div>
          <div className="font-bold">$0.00</div>
        </>
      );
    }
    return `From ${coursePreview.priceRange}`;
  };

  const showSubscription = coursePreview.priceRange && included;
  const subscriptionIcon = user?.program
    ? getSubscriptionIcon(user.program)
    : null;

  return (
    <>
      {showSubscription && subscriptionIcon && (
        <div className="w-6 h-6 absolute end-0 mr-2 mt-2 rounded bg-white p-[1px]">
          <img src={subscriptionIcon} alt="" />
        </div>
      )}
      <LoadingImage
        className="w-full h-40 rounded-t-2xl object-cover"
        src={coursePreview.imageUrl ?? icons.coursePlaceholder}
        alt={coursePreview.name}
      />
      <div className="p-4 pb-6 flex flex-col gap-2 font-medium">
        <div className="text-lg text-core-dark font-bold">
          {coursePreview.name}
        </div>
        {coursePreview.nearestStartTime && (
          <div className="text-regular text-core-dark font-bold uppercase">
            {formatToSimpleDateTime(coursePreview.nearestStartTime)}
          </div>
        )}
        <div
          className="text-regular text-grey-500 line-clamp-3"
          dangerouslySetInnerHTML={{ __html: coursePreview.whoShouldAttend }}
        ></div>
        <div className="flex justify-between text-regular">
          <div className="flex gap-1 text-core-dark items-center whitespace-nowrap">
            <img src={icons.online} alt="" />
            <span>Live Online</span>
          </div>
          {onClick ? (
            <>
              {!isBooked ? (
                <div
                  className="text-blue-primary cursor-pointer hover:underline"
                  onClick={onClick}
                >
                  Book this course
                </div>
              ) : (
                <Tag
                  text="Already booked"
                  color="yellow"
                  className="self-start"
                />
              )}
            </>
          ) : (
            <div className="pl-2 text-blue-primary text-right">
              {renderPrice()}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CourseCard;
