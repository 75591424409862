import React from 'react';

import { ICompany } from '../../../../models/ICompany';
import { companyLogo } from '../../../../helpers';

interface ICompanyBanner {
  company: ICompany;
}

const CompanyBanner: React.FC<ICompanyBanner> = ({ company }) => {
  return (
    <div className="banner-container">
      <div className="flex items-center gap-2">
        <div className="shrink-0">
          <img
            className="w-16 h-16 rounded-full"
            src={companyLogo(company)}
            alt=""
          />
        </div>
        <div className="title-calistoga">{company.name}</div>
      </div>
    </div>
  );
};

export default CompanyBanner;
