import React, { Fragment, useState } from 'react';

import { Combobox, Transition } from '@headlessui/react';
import { autoUpdate, flip, useFloating } from '@floating-ui/react-dom';

import { TStudent } from '../../../../types';
import { icons } from '../../../../helpers/icons';

interface IStudentSelect {
  toggleStudent: (studentId: string) => void;
  availableStudents: TStudent[];
}

const StudentSelect: React.FC<IStudentSelect> = ({
  availableStudents,
  toggleStudent,
}) => {
  const [query, setQuery] = useState('');

  // flip the combobox menu if there is not enough space
  const { refs, floatingStyles } = useFloating({
    middleware: [flip()],
    whileElementsMounted: autoUpdate,
  });

  const filteredStudents =
    query === ''
      ? availableStudents
      : availableStudents.filter((student) =>
          student.email
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, '')),
        );

  return (
    <Combobox value={null} onChange={toggleStudent} nullable>
      <div className="relative cursor-pointer">
        <div
          ref={refs.setReference}
          className="relative w-full max-w-[16rem] border border-1 border-grey-500 rounded-3xl"
        >
          <Combobox.Input
            placeholder="Enter student’s email"
            className="w-full border-none rounded-3xl py-1 pl-2 pr-10 text-core-dark placeholder:text-regular"
            onChange={(event) => setQuery(event.target.value)}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <img src={icons.arrowDown} alt="" />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options
            ref={refs.setFloating}
            style={floatingStyles}
            className="absolute z-50 mt-1 p-2 w-full max-h-60 overflow-auto rounded-lg bg-white shadow-main"
          >
            {filteredStudents.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none py-1 text-grey-200 text-center">
                No options
              </div>
            ) : (
              filteredStudents.map((student) => (
                <Combobox.Option
                  key={student.id}
                  value={student.id}
                  className={({ active }) =>
                    `relative py-1 pl-1 text-core-dark rounded cursor-pointer select-none ${
                      active ? 'bg-grey-100' : ''
                    }`
                  }
                >
                  <span className="block truncate">{student.email}</span>
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

export default StudentSelect;
