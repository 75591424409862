import React from 'react';

import { isFuture, isWithinInterval } from 'date-fns';

import Tag from '../../Tag';
import { TClassStatus, TColor } from '../../../../types';
import { useAppSelector } from '../../../../hooks/hooks';
import { UserRole } from '../../../../models/IUser';

interface IClassStatus {
  status: TClassStatus;
  attended: boolean;
  startTime: string;
  endTime: string;
}

const ClassStatus: React.FC<IClassStatus> = ({
  status,
  startTime,
  endTime,
}: IClassStatus) => {
  const { user } = useAppSelector((state) => state.userReducer);
  const isUserInstructor = user?.role === UserRole.INSTRUCTOR;

  const resolveStatus = (): { text: string; color: TColor } => {
    const now = new Date();
    const start = new Date(startTime);
    const end = new Date(endTime);
    const isUpcoming = isFuture(end);

    if (isUpcoming && !isUserInstructor) {
      if (status === 'Cancelled') {
        return { text: 'Cancelled', color: 'red' };
      }
      if (end > start && isWithinInterval(now, { start, end })) {
        return { text: 'Ongoing', color: 'yellow' };
      }
      return { text: 'Upcoming', color: 'primary' };
    }

    switch (status) {
      case 'No Attend':
        return { text: 'Missed', color: 'red' };
      case 'Completed':
      case 'Attended':
        return { text: status, color: 'green' };
      case 'Cancelled':
        return { text: 'Cancelled', color: 'red' };
      case null:
      case 'Booked':
        if (!startTime || !endTime || isUserInstructor) {
          return { text: 'Unknown', color: 'neutral' };
        }
        if (isUpcoming) {
          return { text: 'Upcoming', color: 'primary' };
        }
        return { text: 'Missed', color: 'red' };
      case 'Transferred':
        return { text: 'Transferred', color: 'yellow' };
      default:
        if (isUserInstructor) {
          return { text: status ?? 'Unknown', color: 'neutral' };
        } else {
          return { text: 'Unknown', color: 'neutral' };
        }
    }
  };
  return <Tag {...resolveStatus()} />;
};

export default ClassStatus;
