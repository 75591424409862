import React from 'react';

import { Link } from 'react-router-dom';

import { ButtonRegular } from '../../buttons/ButtonRegular';
import { NAVIGATE_PATH } from '../../../../helpers/constants';

interface ICheckoutSectionHeader {
  header: string;
  description?: string;
  showShoppingButton?: boolean;
}

const CheckoutSectionHeader: React.FC<ICheckoutSectionHeader> = ({
  header,
  description,
  showShoppingButton = true,
}) => {
  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex gap-4 sm:items-center justify-between">
        <div className="w-full flex flex-col gap-4">
          <div className="flex flex-wrap justify-between items-center">
            <h1 className="checkout-header">{header}</h1>
            {showShoppingButton && (
              <Link
                to={NAVIGATE_PATH.publicCourses}
                className="sm:hidden sm:guest:block text-regular text-blue-primary font-medium text-right"
              >
                Continue Shopping
              </Link>
            )}
          </div>
        </div>
        {showShoppingButton && (
          <Link
            to={NAVIGATE_PATH.publicCourses}
            className="max-sm:hidden guest:hidden"
          >
            <ButtonRegular
              text="Continue Shopping"
              className="button-outline"
            ></ButtonRegular>
          </Link>
        )}
      </div>
      {description && (
        <p className="text-regular text-grey-700 font-medium">{description}</p>
      )}
    </div>
  );
};

export default CheckoutSectionHeader;
