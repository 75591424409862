import React, { useState } from 'react';

import ContentLoader from 'react-content-loader';
import { icons } from '../../../helpers/icons';

interface ILoadingImage {
  src: string;
  alt: string;
  className: string;
}

const LoadingImage: React.FC<Partial<ILoadingImage>> = ({
  src,
  alt,
  className = '',
}) => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  const isPlaceholder = src === icons.coursePlaceholder;

  return (
    <div className="shrink-0">
      {isImageLoading && (
        <ContentLoader className={className}>
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
        </ContentLoader>
      )}
      <img
        onLoad={() => setIsImageLoading(false)}
        className={
          isImageLoading
            ? 'h-0'
            : `${className} ${isPlaceholder ? '!object-left-bottom' : ''}`
        }
        src={src}
        alt={alt}
        loading="lazy"
      />
    </div>
  );
};

export default LoadingImage;
