import React from 'react';

import { addDays, format } from 'date-fns';
import { FormikHelpers } from 'formik/dist/types';
import { useFormik } from 'formik';

import Sidebar from '../../common/Sidebar';
import { ButtonRegular } from '../../common/buttons/ButtonRegular';
import { TProgramInvite, TProgramInviteValues } from '../../../types';
import { generateProgramInvite } from '../../../requests';
import { DEFAULT_ERROR_MESSAGE } from '../../../helpers/constants';
import { showToast } from '../../../helpers';

interface IProgramInvitesSidebar {
  isSidebarOpen: boolean;
  setSidebarOpen: (value: boolean) => void;
  onNewInvite: (invite: TProgramInvite) => void;
}

const ProgramInvitesSidebar: React.FC<IProgramInvitesSidebar> = ({
  onNewInvite,
  isSidebarOpen,
  setSidebarOpen,
}) => {
  const handleGenerateSubmit = async (
    values: TProgramInviteValues,
    formikHelpers: FormikHelpers<TProgramInviteValues>,
  ) => {
    try {
      const invite = await generateProgramInvite(values);
      onNewInvite(invite);
      formikHelpers.resetForm();
    } catch (error: any) {
      const message = error.response?.data?.message;
      showToast(message ?? DEFAULT_ERROR_MESSAGE, 'error');
    }
  };

  const formik = useFormik({
    initialValues: {
      sfProgramId: '',
      maxRegistrations: '',
      expiresAt: '',
    },
    onSubmit: handleGenerateSubmit,
  });

  // min expiresAt for invite is tomorrow
  const minDate = format(addDays(new Date(), 1), 'yyyy-MM-dd');

  return (
    <Sidebar
      isOpen={isSidebarOpen}
      setIsOpen={setSidebarOpen}
      title="Generate invite link"
    >
      <div className="flex flex-col gap-1">
        <p className="text-regular font-medium text-grey-700">
          Create a new Program Invite link that you can share with customers in
          order to make it easy for students to click and automatically join the
          program.
        </p>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
          <table className="settings-table border-t border-b border-gray-200">
            <tbody>
              <tr>
                <td>SF Program ID</td>
                <td>
                  <input
                    type="text"
                    name="sfProgramId"
                    placeholder="Enter ID"
                    onChange={formik.handleChange}
                    value={formik.values.sfProgramId}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td>Expires</td>
                <td>
                  <input
                    type="date"
                    min={minDate}
                    name="expiresAt"
                    placeholder="DD/MM/YYYY"
                    onChange={formik.handleChange}
                    value={formik.values.expiresAt}
                  />
                </td>
              </tr>
              <tr>
                <td>Max users</td>
                <td>
                  <input
                    type="number"
                    min={1}
                    name="maxRegistrations"
                    placeholder="Enter number"
                    onChange={formik.handleChange}
                    value={formik.values.maxRegistrations}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <ButtonRegular
            type="submit"
            text="Generate"
            className="button-primary self-end"
            disabled={formik.isSubmitting}
          />
        </form>
      </div>
    </Sidebar>
  );
};

export default ProgramInvitesSidebar;
