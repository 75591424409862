import React from 'react';

interface IDelimiter {
  className?: string;
}

const Delimiter: React.FC<IDelimiter> = ({ className = '' }) => {
  return <div className={`h-[1px] bg-grey-100 ${className}`} />;
};

export default Delimiter;
