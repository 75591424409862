import React from 'react';

import { Navigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';

import BackButton from '../../../components/common/buttons/BackButton';
import CheckoutList from '../../../components/common/checkout/CheckoutList';
import Delimiter from '../../../components/common/Delimiter';
import PaymentForm from '../../../components/common/checkout/PaymentForm';
import OrderSummaryCard from '../../../components/common/checkout/OrderSummaryCard';
import NavHeader from '../../../components/common/NavHeader';
import { useAppSelector } from '../../../hooks/hooks';
import { NAVIGATE_PATH as PATH } from '../../../helpers/constants';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const SecondCheckoutStep: React.FC = () => {
  const { checkoutStudents } = useAppSelector((state) => state.checkoutReducer);
  const { cart, user, cartTotalToPay } = useAppSelector(
    (state) => state.userReducer,
  );

  if (!cart.public.length) {
    return <Navigate to={PATH.checkoutSummary} replace />;
  }

  if (cart.public.some(({ included }) => included) || cart.private.length) {
    return <Navigate to={PATH.firstCheckoutStep} replace />;
  }

  if (!user && !checkoutStudents) {
    return <Navigate to={PATH.checkoutGuest} replace />;
  }

  const options: StripeElementsOptions = {
    mode: 'payment',
    currency: 'usd',
    amount: cartTotalToPay * 100,
    appearance: {
      theme: 'flat',
      variables: {
        colorText: '#4A4A68',
      },
      rules: {
        '.Label': {
          color: '#4A4A68',
          fontWeight: '500',
          fontFamily: 'Work Sans, sans-serif',
        },
        '.Input': {
          border: '1px solid #BABABA',
          backgroundColor: 'white',
        },
      },
    },
  };

  return (
    <>
      <NavHeader
        label={
          <BackButton
            defaultRoute={user ? PATH.checkoutSummary : PATH.checkoutGuest}
          />
        }
      />
      <div className="main-container guest:items-center w-full md:pt-4 pb-24 max-md:px-2 mb-4 guest:mb-0 max-lg:mb-16 gap-2 bg-white">
        <div className="guest:guest-wrapper guest:w-full flex flex-col xl:flex-row gap-6 justify-between">
          <div className="flex flex-col flex-grow gap-4">
            {user?.program && cart.status === 'second_step' && (
              <>
                <h2 className="text-xl font-bold text-blue-primary">
                  Step 2/2
                </h2>
                <p className="text-regular font-medium text-core-dark">
                  In this step you can pay and book all the classes that aren’t
                  eligible for {user.program.type}
                </p>
              </>
            )}

            <div className="checkout-header">
              {cart.status === 'second_step'
                ? 'Pay for remaining classes'
                : 'Pay for the following classes'}
            </div>
            <Delimiter className="bg-grey-200" />
            <p className="text-regular font-medium text-core-dark">
              Below are the classes you need to pay for in order to finalize the
              booking.
            </p>
            <CheckoutList />
            <Delimiter className="bg-grey-200" />
            <Elements stripe={stripePromise} options={options}>
              <PaymentForm />
            </Elements>
          </div>
          <div className="w-full xl:max-w-[22rem] 3xl:min-w-[35%]">
            <OrderSummaryCard buttonHidden />
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondCheckoutStep;
