import React, { useEffect, useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import CourseSearchSuggestion from './CourseSearchSuggestion';
import { icons } from '../../../../../helpers/icons';
import { TCoursePreview } from '../../../../../types';
import { useAppSelector } from '../../../../../hooks/hooks';

const CourseSearchBar: React.FC = () => {
  const { allCourses } = useAppSelector((state) => state.libraryReducer);
  const [searchedCourses, setSearchedCourses] = useState<TCoursePreview[]>([]);
  const [courses, setCourses] = useState<TCoursePreview[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    setCourses(
      allCourses.reduce((acc: TCoursePreview[], courseGroup) => {
        acc.push(...courseGroup.courses);
        return acc;
      }, []),
    );
  }, [allCourses]);

  const debouncedSearch = useDebouncedCallback((value: string) => {
    if (!value) {
      setSearchedCourses([]);
      return;
    }
    const searchedName = value.toLowerCase();
    const filteredCourses = courses.filter((course) => {
      return course.name.toLowerCase().includes(searchedName);
    });
    setSearchedCourses(filteredCourses.slice(0, 10));
  }, 100);

  return (
    <div className="w-full max-w-[800px] relative z-20">
      <div className="absolute inset-y-0 flex items-center pointer-events-none end-0 pe-6">
        <img src={icons.search} className="w-6 h-6" alt="" />
      </div>
      <input
        type="text"
        autoComplete="off"
        className={`w-full p-6 pr-14 rounded-2xl text-black font-bold text-lg 
                outline-0 focus-visible:border-blue-primary border border-grey-200
                placeholder:text-lg placeholder:text-grey-700 placeholder:font-bold`}
        placeholder="Search for a course"
        onChange={(e) => debouncedSearch(e.target.value)}
        onFocus={() => setShowSuggestions(true)}
        onBlur={() => setShowSuggestions(false)}
      />
      <div
        onMouseDown={(e) => e.preventDefault()}
        className={`${
          showSuggestions && searchedCourses.length ? 'block' : 'hidden'
        } absolute mt-1 p-2 w-full bg-white rounded-lg shadow-float`}
      >
        {searchedCourses.map((course) => (
          <CourseSearchSuggestion key={course.id} course={course} />
        ))}
      </div>
    </div>
  );
};

export default CourseSearchBar;
