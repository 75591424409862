import React from 'react';

import { Form, Formik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik/dist/types';

import AgreementDescription from '../../common/AuthLayout/AgreementDescription';
import { ButtonRegular } from '../../common/buttons/ButtonRegular';
import { FormInput } from '../../common/forms/FormsInput';
import {
  getLoginValidate,
  loginInitialValue,
} from '../../../helpers/validateLoginData';
import { icons } from '../../../helpers/icons';
import { NAVIGATE_PATH as PATH } from '../../../helpers/constants';
import { TLoginValues } from '../../../types';
import { useAppDispatch } from '../../../hooks/hooks';
import { login } from '../../../requests';
import { clearUserState } from '../../../store/reducers/userSlice';
import { showPostInviteMessage } from '../../../helpers';

interface ILoginForm {
  className?: string;
  header?: string;
  setEmailSent: (emailSent: boolean) => void;
  isCheckoutLogin?: boolean;
}

export const LoginForm: React.FC<ILoginForm> = ({
  className = '',
  header = 'Log in',
  setEmailSent,
  isCheckoutLogin,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (
    values: TLoginValues,
    formikHelpers: FormikHelpers<TLoginValues>,
  ) => {
    try {
      const response = await login(values.email, values.password);
      if (response.accessToken) {
        dispatch(clearUserState());
        localStorage.setItem('token', response.accessToken);
        navigate(isCheckoutLogin ? PATH.checkoutSummary : PATH.upcomingClasses);
        if (response.resolvedInvite) {
          showPostInviteMessage(response.resolvedInvite);
        }
      } else if (response.email) {
        setEmailSent(true);
      }
    } catch (e: any) {
      if (e.response?.status == 401) {
        formikHelpers.setFieldError(
          'password',
          'email or password is incorrect',
        );
      } else {
        console.error(e);
      }
    }
  };

  return (
    <div className={`form-container ${className}`}>
      {!isCheckoutLogin && (
        <img
          className="form-illustration-left"
          src={icons.flyingIllustration}
          alt=""
        />
      )}
      <Formik
        validate={getLoginValidate}
        initialValues={loginInitialValue}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          handleSubmit,
          handleBlur,
          handleChange,
          values,
          touched,
          errors,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="lg:max-w-[440px] m-auto 2xl:m-0 grid justify-items-center">
              <div className="form-title">{header}</div>
              <FormInput
                handleChange={handleChange}
                className="w-full mb-6"
                name={'email'}
                type={'email'}
                handleBlur={handleBlur}
                value={values.email}
                placeholder="Email"
                touched={touched['email']}
                error={errors['email']}
              />
              <FormInput
                handleChange={handleChange}
                className="w-full mb-6"
                name={'password'}
                type={'password'}
                handleBlur={handleBlur}
                value={values.password}
                placeholder="Password"
                touched={touched['password']}
                error={errors['password']}
              />
              <AgreementDescription action="Log in" />
              <div className="grid w-full mt-4 justify-items-center">
                <ButtonRegular
                  className="button-primary"
                  type={'submit'}
                  text="log in"
                  disabled={isSubmitting}
                />
              </div>
              <div className="grid w-full mt-4 justify-items-center">
                <span className="font-medium">
                  Forgot password?
                  <Link to={PATH.resetPassword}>
                    <span className="text-blue-one"> Click here</span>
                  </Link>
                </span>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
