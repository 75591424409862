import React from 'react';

import CourseSearchBar from './CourseSearchBar';
import Tag from '../../Tag';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import {
  filterCourses,
  setSecondaryFilters,
} from '../../../../store/reducers/librarySlice';
import { TNamedItem } from '../../../../types';

const CourseSearch: React.FC = () => {
  const dispatch = useAppDispatch();
  const { filters, activeFilters } = useAppSelector(
    (state) => state.libraryReducer,
  );

  const handleParentCategoryChange = (primaryFilter: TNamedItem | null) => {
    dispatch(
      filterCourses({
        primaryFilter:
          activeFilters.primaryFilter?.id !== primaryFilter?.id
            ? primaryFilter
            : null,
        secondaryFilter: null,
      }),
    );

    if (primaryFilter) {
      dispatch(setSecondaryFilters(primaryFilter));
    }
  };

  return (
    <div className="main-container relative items-center px-4 pt-4 pb-8 xl:pt-20 xl:pb-16 bg-white">
      <div className="w-full flex flex-col guest:guest-wrapper">
        <div className="flex justify-center">
          <CourseSearchBar />
        </div>
        <div className="pt-6 pb-7 flex gap-5 flex-wrap justify-center z-10">
          {filters.parentCategories.map((category) => (
            <Tag
              key={category.id}
              text={category.name}
              color={
                activeFilters.primaryFilter?.id === category.id
                  ? 'primary'
                  : 'neutral'
              }
              onClick={() => handleParentCategoryChange(category)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CourseSearch;
