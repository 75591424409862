import React, { useEffect } from 'react';

import { Navigate } from 'react-router-dom';

import BackButton from '../../../components/common/buttons/BackButton';
import CheckoutOverview from '../../../components/common/checkout/CheckoutOverview';
import CheckoutPlaceholder from '../../../components/common/checkout/CheckoutOverview/CheckoutPlaceholder';
import OrderSummaryCard from '../../../components/common/checkout/OrderSummaryCard';
import NavHeader from '../../../components/common/NavHeader';
import { NAVIGATE_PATH } from '../../../helpers/constants';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { setCheckoutStatus } from '../../../store/reducers/userSlice';

const CheckoutSummary: React.FC = () => {
  const dispatch = useAppDispatch();
  const { user, cart } = useAppSelector((state) => state.userReducer);

  const isCartEmpty = cart.public.length + cart.private.length === 0;

  useEffect(() => {
    dispatch(setCheckoutStatus('open'));
  }, []);

  if (!user && isCartEmpty) {
    return <Navigate to={NAVIGATE_PATH.publicCourses} replace />;
  }

  return (
    <>
      <NavHeader
        label={
          <BackButton defaultRoute={NAVIGATE_PATH.publicCourses} usePrevious />
        }
      />
      <div className="main-container guest:items-center w-full md:pt-4 max-md:px-2 gap-2 bg-white">
        <div className="guest:guest-wrapper guest:w-full flex flex-col xl:flex-row gap-6 justify-between">
          {isCartEmpty ? <CheckoutPlaceholder /> : <CheckoutOverview />}
          <div className="w-full xl:max-w-[22rem] 3xl:min-w-[35%]">
            <OrderSummaryCard />
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutSummary;
