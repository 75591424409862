import React from 'react';

import { NavLink } from 'react-router-dom';

import MiniTag from '../../../MiniTag';
import LoadingImage from '../../../LoadingImage';
import { NAVIGATE_PATH } from '../../../../../helpers/constants';
import { icons } from '../../../../../helpers/icons';
import { useAppSelector } from '../../../../../hooks/hooks';
import { companyLogo } from '../../../../../helpers';

const Company: React.FC = () => {
  const { user } = useAppSelector((state) => state.userReducer);

  if (user?.individual) {
    return null;
  }

  return (
    <div className="hidden lg:flex items-center gap-4 m-2 mb-4">
      <div className="flex gap-4 items-center">
        <NavLink to={NAVIGATE_PATH.companyInfo}>
          <LoadingImage
            className="w-12 h-12 rounded-lg"
            src={companyLogo(user?.company)}
          />
        </NavLink>
        <div>
          <div
            className="w-52 text-heading-dark text-ellipsis overflow-hidden"
            title={user?.company?.name}
          >
            {user?.company?.name}
          </div>
          {user?.program && (
            <MiniTag text={user.program.type} color="neutral" />
          )}
        </div>
      </div>
      <div className="hidden">
        <img src={icons.arrowDown} alt="arrowdown" />
      </div>
    </div>
  );
};

export default Company;
