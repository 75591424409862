import React, { useEffect } from 'react';

import { Form, Formik } from 'formik';
import { Navigate, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import BackButton from '../../../components/common/buttons/BackButton';
import CheckoutAddStudents from '../../../components/common/checkout/CheckoutAddStudents';
import CheckoutSectionHeader from '../../../components/common/checkout/CheckoutSectionHeader';
import CheckoutSummaryTable from '../../../components/common/checkout/CheckoutOverview/CheckoutSummaryTable';
import Delimiter from '../../../components/common/Delimiter';
import OrderSummaryCard from '../../../components/common/checkout/OrderSummaryCard';
import NavHeader from '../../../components/common/NavHeader';
import { TStudent } from '../../../types';
import { NAVIGATE_PATH } from '../../../helpers/constants';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { setCheckoutStatus } from '../../../store/reducers/userSlice';
import { getFromLocalStorage } from '../../../helpers/localStorage';
import { setCheckoutStudents } from '../../../store/reducers/checkoutSlice';

const studentSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('First name is required'),
  lastName: Yup.string().trim().required('Last name is required'),
  email: Yup.string()
    .trim()
    .required('Email is required')
    .email('Invalid email')
    .test('unique-email', 'Email must be unique', function (value) {
      const occurrences = this.options.context?.students.filter(
        (student: TStudent) => student.email === value,
      );
      return occurrences?.length === 1;
    }),
});

const validationSchema = Yup.object().shape({
  students: Yup.array()
    .of(studentSchema)
    .min(1, 'At least one student is required'),
});

const CheckoutGuest: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, cart } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(setCheckoutStatus('open'));
  }, []);

  if (user) {
    return <Navigate to={NAVIGATE_PATH.checkoutSummary} replace />;
  }

  if (!cart.public.length) {
    return <Navigate to={NAVIGATE_PATH.publicCourses} replace />;
  }

  const savedStudents = getFromLocalStorage<TStudent[]>('checkoutStudents');
  const students = savedStudents ?? [
    { id: uuidv4(), firstName: '', lastName: '', email: '' },
  ];
  const isAssignmentValid = cart.public.every(
    (item) => item.studentIds?.length,
  );

  return (
    <>
      <NavHeader
        label={<BackButton defaultRoute={NAVIGATE_PATH.publicCourses} />}
      />
      <div className="main-container guest:items-center w-full md:pt-4 max-md:px-2 gap-2 bg-white">
        <Formik
          initialValues={{ students }}
          onSubmit={(values) => {
            dispatch(setCheckoutStudents(values.students));
            navigate(NAVIGATE_PATH.secondCheckoutStep);
          }}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur
        >
          {({ values }) => (
            <Form className="guest:guest-wrapper guest:w-full flex flex-col xl:flex-row gap-6 justify-between">
              <div className="flex flex-col gap-4">
                <CheckoutAddStudents />
                <Delimiter className="sm:hidden mt-4 bg-grey-200" />
                <section className="sm:px-6 sm:mt-4">
                  <CheckoutSectionHeader
                    header="2. Continue to checkout"
                    description="Assign the email to the relevant course"
                  />
                  <Delimiter className="mt-2 md:mb-6 bg-grey-200" />
                  <CheckoutSummaryTable students={values.students} />
                </section>
              </div>
              <div className="mb-16 w-full xl:max-w-[22rem] 3xl:min-w-[35%]">
                <OrderSummaryCard
                  nextStepDisabled={
                    values.students.length > 1 && !isAssignmentValid
                  }
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CheckoutGuest;
