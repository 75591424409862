import React, { useState } from 'react';

import { TReview } from '../../../../../types';

interface ICourseReviews {
  reviews: TReview[];
}

const CourseReviews: React.FC<ICourseReviews> = ({ reviews }) => {
  const [showAll, setShowAll] = useState(false);

  return (
    reviews.length > 0 && (
      <div className="course-section bg-white">
        <div className="guest-wrapper guest:mx-auto flex flex-col gap-6">
          <div className="text-2xl text-grey-700 font-bold">Course reviews</div>
          <div className="flex gap-4 flex-wrap">
            {reviews.slice(0, showAll ? reviews.length : 3).map((review) => (
              <div key={review.id} className="review-card p-4 pb-6">
                <div className="text-regular text-grey-500 font-medium">
                  {review.quote}
                </div>
                <div className="text-lg text-core-dark font-bold">
                  {review.name}
                </div>
              </div>
            ))}
          </div>
          {reviews.length > 3 && !showAll && (
            <div
              className="self-end text-sm text-blue-primary underline cursor-pointer"
              onClick={() => setShowAll(true)}
            >
              See all reviews
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default CourseReviews;
