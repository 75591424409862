import React, { Fragment, ReactNode } from 'react';

import { Dialog, Transition } from '@headlessui/react';

import { icons } from '../../../helpers/icons';

interface ISidebar {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title: string;
  children: ReactNode;
}

const Sidebar: React.FC<ISidebar> = ({
  isOpen,
  setIsOpen,
  title,
  children,
}) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-[100]"
        onClose={setIsOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300 sm:duration-700"
              leaveFrom="-translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="px-6 py-4 h-full flex flex-col bg-white shadow-xl rounded-l-lg">
                  <div className="relative mb-10 flex items-center">
                    <img
                      src={icons.close}
                      alt=""
                      className="absolute p-1 -left-2 cursor-pointer"
                      onClick={() => setIsOpen(false)}
                    />
                    <p className="ml-5 text-lg text-core-dark font-bold">
                      Close
                    </p>
                  </div>
                  <Dialog.Title className="mb-1 checkout-header">
                    {title}
                  </Dialog.Title>
                  <div className="relative flex-1">{children}</div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Sidebar;
