import { createAsyncThunk } from '@reduxjs/toolkit';

import { getAccessToken } from '../../helpers/localStorage';
import { getMe } from '../../requests';

export const fetchUser = createAsyncThunk('user/me', async (_, thunkAPI) => {
  if (!getAccessToken()) {
    return thunkAPI.rejectWithValue('Access token is missing');
  }
  try {
    return await getMe();
  } catch (e) {
    return thunkAPI.rejectWithValue("Can't load user");
  }
});
