import React from 'react';

import { Toast, toast } from 'react-hot-toast';

interface ILongToast {
  newToast: Toast;
}

const ToastBar: React.FC<ILongToast> = ({ newToast }) => {
  return (
    <div className="w-full">
      <div
        className={`${
          newToast.type === 'success'
            ? 'bg-green-100 text-blue-primary'
            : 'bg-red-100 text-red-900'
        } max-w-[1430px] m-auto px-4 py-2 flex items-center justify-between gap-2 text-sm sm:text-regular rounded-lg`}
      >
        <div className="font-medium">
          <span className="font-bold capitalize">{newToast.type}: </span>
          {newToast.message?.toString()}
        </div>
        <button
          onClick={() => toast.remove(newToast.id)}
          className="font-bold uppercase"
        >
          Dismiss
        </button>
      </div>
    </div>
  );
};

export default ToastBar;
