import React, { useEffect, useState } from 'react';

import ContentLoader from 'react-content-loader';
import { useNavigate } from 'react-router-dom';

import CourseCard from '../../../components/common/library/CourseCard';
import ClassesNotFound from '../../../components/common/dashboard/ClassesNotFound';
import SubNavigation from '../../../components/common/SubNavigation';
import NavHeader from '../../../components/common/NavHeader';
import { getPrivateCourses } from '../../../requests';
import { TPrivateCourse } from '../../../types';
import { NAVIGATE_PATH, NAVIGATION_INFO } from '../../../helpers/constants';
import { useAppDispatch } from '../../../hooks/hooks';
import { addPrivateCourse } from '../../../store/reducers/userSlice';

const PrivateCourses = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [courses, setCourses] = useState<TPrivateCourse[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPrivateCourses()
      .then((courses) => {
        setCourses(courses);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  const handleCourseBooking = (privateCourse: TPrivateCourse) => {
    const { name, imageUrl } = privateCourse.coursePreview;
    dispatch(
      addPrivateCourse({
        ...privateCourse.availableTime,
        course: { name, imageUrl },
        included: true,
      }),
    );
    navigate(NAVIGATE_PATH.checkoutSummary);
  };

  return (
    <>
      <NavHeader
        label="Private courses"
        className="!bg-grey-50 border border-b-1 border-grey-100"
      />
      <SubNavigation items={NAVIGATION_INFO.library.items} />
      <div className="main-container bg-grey-50 min-h-screen">
        <div className="course-group-container py-6">
          {loading ? (
            <ContentLoader className="private-course-card min-h-[20rem]">
              <rect x="0" y="0" rx="5" ry="0" width="100%" height="160" />
              <rect x="16" y="180" rx="5" ry="5" width="60%" height="28" />
              <rect x="16" y="228" rx="5" ry="5" width="88%" height="65" />
            </ContentLoader>
          ) : (
            !courses.length && (
              <ClassesNotFound
                title="There are no private classes to book at the moment"
                description="Please check with your company representative
                regarding available private classes with Learnit."
                buttonText="Discover public classes"
              />
            )
          )}
          {courses.map((course) => (
            <div key={course.availableTime.id} className="private-course-card">
              <CourseCard
                coursePreview={course.coursePreview}
                isBooked={course.availableTime.isBooked}
                onClick={() => handleCourseBooking(course)}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PrivateCourses;
