import React from 'react';

import { icons } from '../../helpers/icons';

const NotFound: React.FC = () => {
  return (
    <>
      <div className="main-container h-[70vh] gap-6 justify-center items-center text-grey-700">
        <div>
          <img src={icons.cannotFindIllustration} alt="" />
        </div>
        <div className="calistoga text-6xl">404</div>
        <div className="text-regular font-medium text-center">
          Looks like this link does’t exist or the page has moved.
        </div>
      </div>
    </>
  );
};

export default NotFound;
