import {
  TConfirmPasswordValue,
  TLoginValues,
  TResetPasswordValue,
  TSignUpValues,
} from '../types';
import { FormikErrors } from 'formik';

const INVALID_EMAIL_ADDRESS = 'Invalid email address';
const INVALID_CONFIRM_PASSWORD = 'Password and Confirm Password does not match';
const INVALID_FIRST_NAME = 'Invalid First name';
const INVALID_LAST_NAME = 'Invalid Last name';

export const loginInitialValue = {
  email: '',
  password: '',
};

export const signUpInitialValue = {
  firstName: '',
  lastName: '',
  email: '',
};

export const resetPasswordValue = {
  email: '',
};

export const confirmPasswordValue = {
  password: '',
  confirmPassword: '',
};

export const getLoginValidate = (values: TLoginValues) => {
  const errors: FormikErrors<TLoginValues> = {};
  const REGULAR_CORRECT_EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  if (!REGULAR_CORRECT_EMAIL.test(values.email || '')) {
    errors.email = INVALID_EMAIL_ADDRESS;
  }
  return errors;
};

export const getResetPasswordValidate = (values: TResetPasswordValue) => {
  const errors: FormikErrors<TResetPasswordValue> = {};
  const REGULAR_CORRECT_EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  if (!REGULAR_CORRECT_EMAIL.test(values.email || '')) {
    errors.email = INVALID_EMAIL_ADDRESS;
  }

  return errors;
};

export const getSignUpValidate = (values: TSignUpValues) => {
  const errors: FormikErrors<TSignUpValues> = {};
  const REGULAR_CORRECT_EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  if (!REGULAR_CORRECT_EMAIL.test(values.email || '')) {
    errors.email = INVALID_EMAIL_ADDRESS;
  }
  if (!values.firstName) {
    errors.firstName = INVALID_FIRST_NAME;
  }
  if (!values.lastName) {
    errors.lastName = INVALID_LAST_NAME;
  }

  return errors;
};

export const getConfirmPasswordValidate = (values: TConfirmPasswordValue) => {
  const errors: FormikErrors<TConfirmPasswordValue> = {};
  validatePassword(values.password, errors);
  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = INVALID_CONFIRM_PASSWORD;
  }

  return errors;
};

const validatePassword = (
  password: string,
  errors: FormikErrors<{ password: string }>,
) => {
  if (password.length < 8) {
    errors.password = 'Password must be at least 8 characters long';
    return;
  }
  if (!/[a-z]/.test(password || '')) {
    errors.password = 'Password must include lowercase letters';
    return;
  }
  if (!/[A-Z]/.test(password || '')) {
    errors.password = 'Password must include uppercase letters';
    return;
  }
  if (!/[0-9]/.test(password || '')) {
    errors.password = 'Password must include at least one number';
    return;
  }
  if (!/[^A-Za-z0-9]/.test(password || '')) {
    errors.password = 'Password must include at least one special character';
  }
};
